import Iso31661a2 from 'iso-3166-1-alpha-2';
import {
  BBox,
  CleanWFSFeature,
  RawWFSFeature,
  SHIP_TYPES,
  ShipType,
} from '../../../models/geoserver/geoserver.model';

export const parseMapBounds = (
  bounds: mapboxgl.LngLatBounds | undefined
): BBox | null => {
  if (!bounds) {
    return null;
  }
  let minX;
  let maxX;
  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();
  const normalizedNe = ne.wrap();
  const normalizedSw = sw.wrap();
  if (Math.abs(ne.lng - sw.lng) >= 360) {
    minX = -180;
    maxX = 180;
  } else {
    minX = normalizedSw.lng;
    maxX = normalizedNe.lng;
  }
  const minY = Math.min(normalizedNe.lat, normalizedSw.lat);
  const maxY = Math.max(normalizedNe.lat, normalizedSw.lat);
  return [minX, minY, maxX, maxY];
};

export const splitBbox = (bbox: BBox): BBox[] => {
  const xmid = (bbox[0] + bbox[2]) / 2;
  const ymid = (bbox[1] + bbox[3]) / 2;

  // check if we're crossing the antimeridian
  if (bbox[0] > bbox[2]) {
    return [
      [-180, bbox[1], bbox[2], ymid],
      [bbox[0], bbox[1], 180, ymid],
      [-180, ymid, bbox[2], bbox[3]],
      [bbox[0], ymid, 180, bbox[3]],
    ];
  }
  return [
    [bbox[0], bbox[1], xmid, ymid],
    [xmid, bbox[1], bbox[2], ymid],
    [bbox[0], ymid, xmid, bbox[3]],
    [xmid, ymid, bbox[2], bbox[3]],
  ];
};

export function cleanShipTypeAndFlag(data: RawWFSFeature[]): CleanWFSFeature[] {
  const allFlagCodes = new Set(Iso31661a2.getCodes());
  const allShipTypes = new Set(SHIP_TYPES);

  return data.map((feature) => {
    const newFeature: CleanWFSFeature = {
      ...feature,
      properties: {
        ...feature.properties,
        ship_type:
          feature.properties.ship_type == null ||
          !allShipTypes.has(feature.properties.ship_type as ShipType)
            ? 'Other'
            : feature.properties.ship_type,
        flag:
          feature.properties.flag == null ||
          !allFlagCodes.has(feature.properties.flag)
            ? 'Unknown Flag'
            : feature.properties.flag,
      },
    };

    return newFeature;
  });
}
