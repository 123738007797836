import { MapMouseEvent, MapTouchEvent } from 'mapbox-gl';
import { themedDraws } from '../../map/map-controls.utils';
import addSimpleGeoJsonSource from '../../map/map-layer-manager/layer-utils/add-simple-geojson-source';
import MapLayerIcon from '../../map/map-layer-manager/map-layer-icon';
import MapLayerManager from '../../map/map-layer-manager/map-layer-manager.utils';
import MapLayerPaint from '../../map/map-layer-manager/map-layer-paint';
import MapLayerVisibility from '../../map/map-layer-manager/map-layer-visibility.enum';
import MapLayer, {
  MapGroupLayer,
} from '../../map/map-layer-manager/map-layer.enum';
import addVesselsLayer from '../../map/map-layer-manager/vessel-utils/add-vessels-layer';
import AISVesselsController from '../../map/map-layer-manager/vessel-utils/ais-vessel-controller.utils';
import {
  getUniqueHistoricMmsis,
  getUniqueHistoricVessels,
} from '../../map/map-layer-manager/vessel-utils/get-unique-historic-vessels';
import setVesselFeatures from '../../map/map-layer-manager/vessel-utils/set-vessel-features';
import setVesselJourney from '../../map/map-layer-manager/vessel-utils/set-vessel-journey';
import { setPaintProperty } from '../../map/map.slice';
import MapHelpers from '../../map/map.utils';
import store from '../../store';
import TenantId from '../../tenant';
import { Tenant } from '../../theme';
import { UserPreferences } from '../../user-settings/user-preferences/user-preferences.slice';
import GeoHelper from '../../utils/geo-helpers.utils';
import {
  GENERIC_VESSEL,
  GENERIC_VESSEL_SIZE,
} from '../../utils/vessel-constants.utils';
import prepareVesselStylingData from '../../utils/vessel-styling.utils';
import RfEventController from '../rf-data-panel/rf-event-controller.utils';
import { HistoricVesselPoint } from './historic-vessel-point.model';
import AISDataGapsController from './history-ais-data-gaps/ais-data-gaps-controller.utils';
import {
  hideAISDataGapPointPopups,
  toPopup,
} from './history-ais-data-gaps/ais-data-gaps-popup';
import AISMergedPointsController from './history-ais-merged-points/history-ais-merged-points-controller.utils';
import { hideAISMergedPointsPopup } from './history-ais-merged-points/history-ais-merged-points-popup';

namespace VesselHistoryController {
  // TODO: add time argument - could be used for time slider
  export const getHistoricVesselsSnapshot = (points: HistoricVesselPoint[]) => {
    const uniqueVessels = getUniqueHistoricVessels(points);
    const historicVessels: HistoricVesselPoint[] = uniqueVessels
      .map((vessel) =>
        // find last point in vessel journey
        points.reverse().find((point) => point.vessel_id === vessel)
      )
      .filter((item): item is HistoricVesselPoint => !!item);

    return historicVessels;
  };

  export const uniqueHistoryVesselIds: string[] = [];

  export const layerList = {
    JOURNEYS: {
      addLayer: (vesselId: string, data: HistoricVesselPoint[]) => {
        const layerId = `${vesselId}_${MapGroupLayer.VESSEL_HISTORY_JOURNEYS}`;
        const { idToken } = store.getState().user;

        uniqueHistoryVesselIds.push(vesselId);

        if (!MapHelpers.getLayer(layerId)) {
          addSimpleGeoJsonSource(layerId);
          MapHelpers.addLayer({
            id: layerId,
            type: 'line',
            source: layerId,
            layout: {
              visibility: MapLayerVisibility.VISIBLE,
            },
            paint: MapLayerPaint.VESSEL_HISTORY_JOURNEY(
              uniqueHistoryVesselIds.indexOf(vesselId),
              idToken?.tenantId
            ),
            metadata: MapLayerManager.groupLayerMetaData([
              vesselId,
              MapGroupLayer.VESSEL_HISTORY_JOURNEYS,
              MapGroupLayer.HISTORY,
            ]),
          });
        }

        setVesselJourney(data, layerId);
      },
      getLayerKey: (vesselId: string) =>
        `${vesselId}_${MapGroupLayer.VESSEL_HISTORY_JOURNEYS}`,
      groupLayer: MapGroupLayer.VESSEL_HISTORY_JOURNEYS,
    },
    AIS_POSITIONS: {
      addLayer: (vesselId: string, data: HistoricVesselPoint[]) => {
        const layerId = `${vesselId}_${MapGroupLayer.VESSEL_HISTORY_AIS_POSITIONS}`;

        const onClick = (e: MapTouchEvent | MapMouseEvent) => {
          const { idToken } = store.getState().user;
          // If no idToken, safer to proceed
          if (idToken) {
            const draw =
              themedDraws[(idToken.tenantId as Tenant) || TenantId.GEOLLECT];
            if (draw.getMode() !== 'simple_select') return;
          }
          AISVesselsController.layerEvents.onClick(e);
        };

        if (!MapHelpers.getLayer(layerId)) {
          addSimpleGeoJsonSource(layerId);
          MapHelpers.addLayer({
            id: layerId,
            type: 'symbol',
            source: layerId,
            minzoom: 7,
            layout: {
              visibility: MapLayerVisibility.VISIBLE,
              'icon-image': MapLayerIcon.HISTORIC_POSITION,
              'icon-rotation-alignment': 'map',
              'icon-rotate': ['number', ['get', 'course'], 360],
              'icon-size': [
                'interpolate',
                ['linear'],
                ['zoom'],
                // zoom is 5 (or less) -> icon will be half size
                5,
                0.5,
                // zoom is 10 (or greater) -> icon will be full size
                10,
                1,
              ],
            },
            metadata: MapLayerManager.groupLayerMetaData([
              vesselId,
              MapGroupLayer.VESSEL_HISTORY_AIS_POSITIONS,
              MapGroupLayer.HISTORY,
            ]),
          });
        }

        setVesselFeatures(layerId, data);
        MapHelpers.onLayerVisibilityChange(layerId, () => {
          AISMergedPointsController.onAISPositionsLayerVisibilityChange(
            layerId
          );
        });

        MapHelpers.addMapEventListener(
          'mouseenter',
          layerId,
          AISVesselsController.layerEvents.onMouseEnter
        );

        MapHelpers.addMapEventListener(
          'mouseleave',
          layerId,
          AISVesselsController.layerEvents.onMouseLeave
        );

        MapHelpers.addMapEventListener('click', layerId, onClick);
        // prevent touch-then-drag being interpreted as a click
        // if start point is significantly different from end point, or 250ms elapses, then it's a drag
        let isClick = false;
        let point: { x: number; y: number } | null = null;

        MapHelpers.addMapEventListener('touchstart', layerId, (e) => {
          isClick = true;
          point = e.point;
          setTimeout(() => {
            isClick = false;
            point = null;
          }, 250);
        });

        MapHelpers.addMapEventListener('touchend', layerId, (e) => {
          if (isClick && GeoHelper.pointsNear(point, e.point)) onClick(e);
        });
      },
      getLayerKey: (vesselId: string) =>
        `${vesselId}_${MapGroupLayer.VESSEL_HISTORY_AIS_POSITIONS}`,
      groupLayer: MapGroupLayer.VESSEL_HISTORY_AIS_POSITIONS,
    },
    SELECTED_POSITION: {
      addLayer: (
        vesselId: string,
        data: HistoricVesselPoint[],
        userPreferences: UserPreferences
      ) => {
        const layerId = `${vesselId}_${MapGroupLayer.VESSEL_HISTORY_SELECTED_AIS_POSITION}`;

        if (!MapHelpers.getLayer(layerId)) {
          addVesselsLayer(
            layerId,
            MapLayerVisibility.VISIBLE,
            MapLayerManager.groupLayerMetaData([
              vesselId,
              MapGroupLayer.VESSEL_HISTORY_AIS_POSITIONS,
              MapGroupLayer.HISTORY,
            ]),
            userPreferences
          );
        }

        setVesselFeatures(layerId, [data[data.length - 1]]); // default to the last element on the history,
        // which should be the vessels current position.
      },
      getLayerKey: (vesselId: string) =>
        `${vesselId}_${MapGroupLayer.VESSEL_HISTORY_SELECTED_AIS_POSITION}`,
      groupLayer: MapGroupLayer.VESSEL_HISTORY_VESSELS,
    },
    AIS_HEATMAPS: {
      addLayer: (vesselId: string, data: HistoricVesselPoint[]) => {
        const layerId = `${vesselId}_${MapGroupLayer.VESSEL_HISTORY_HEATMAPS}`;

        if (!MapHelpers.getLayer(layerId)) {
          addSimpleGeoJsonSource(layerId);
          MapHelpers.addLayer({
            id: layerId,
            type: 'heatmap',
            source: layerId,
            paint: MapLayerPaint.DEFAULT_HEATMAP,
            layout: {
              visibility: MapLayerVisibility.NOT_VISIBLE,
            },
            metadata: MapLayerManager.groupLayerMetaData([
              vesselId,
              MapGroupLayer.VESSEL_HISTORY_HEATMAPS,
              MapGroupLayer.HISTORY,
            ]),
          });
        }

        setVesselFeatures(layerId, data);
      },
      getLayerKey: (vesselId: string) =>
        `${vesselId}_${MapGroupLayer.VESSEL_HISTORY_HEATMAPS}`,
      groupLayer: MapGroupLayer.VESSEL_HISTORY_HEATMAPS,
    },
    AIS_DATA_GAPS: {
      addLayer: (vesselId: string, data: HistoricVesselPoint[]) => {
        const layerId = `${vesselId}_${MapGroupLayer.VESSEL_HISTORY_AIS_DATA_GAPS}`;

        addSimpleGeoJsonSource(layerId);
        MapLayerManager.AddLayerinGroups(
          {
            id: layerId,
            type: 'line',
            source: layerId,
            layout: {
              visibility: MapLayerVisibility.VISIBLE,
            },
            paint: MapLayerPaint.HIGHLIGHTED_LINES,
            metadata: MapLayerManager.groupLayerMetaData([
              vesselId,
              MapGroupLayer.VESSEL_HISTORY_AIS_DATA_GAPS,
            ]),
          },
          [MapGroupLayer.VESSEL_HISTORY_AIS_DATA_GAPS, MapGroupLayer.HISTORY]
        );

        setVesselFeatures(layerId, data);
      },
      getLayerKey: (vesselId: string) =>
        `${vesselId}_${MapGroupLayer.VESSEL_HISTORY_AIS_DATA_GAPS}`,
      groupLayer: MapGroupLayer.VESSEL_HISTORY_AIS_DATA_GAPS,
    },
    VESSELS: {
      addLayer: (
        vesselId: string,
        data: HistoricVesselPoint[],
        userPreferences: UserPreferences
      ) => {
        const layerId = `${vesselId}_${MapGroupLayer.VESSEL_HISTORY_VESSELS}`;

        if (!MapHelpers.getLayer(layerId)) {
          addVesselsLayer(
            layerId,
            MapLayerVisibility.VISIBLE,
            MapLayerManager.groupLayerMetaData([
              vesselId,
              MapGroupLayer.VESSEL_HISTORY_VESSELS,
              MapGroupLayer.HISTORY,
            ]),
            userPreferences
          );
        }
        store.dispatch(
          setPaintProperty({
            layerId,
            name: 'icon-opacity',
            value: 0.5,
          })
        );

        const snapshot =
          VesselHistoryController.getHistoricVesselsSnapshot(data);

        setVesselFeatures(layerId, snapshot);
      },
      getLayerKey: (vesselId: string) =>
        `${vesselId}_${MapGroupLayer.VESSEL_HISTORY_VESSELS}`,
      groupLayer: MapGroupLayer.VESSEL_HISTORY_VESSELS,
    },
    // RF_DATA: {
    //   addLayer: (vesselId: string, data: HistoricVesselPoint[]) => {
    //     const layerId = `${vesselId}_${MapGroupLayer.RF_TRACKS}`;
    //     const map = MapHelpers.getMapInstance();
    //     addSimpleGeoJsonSource(layerId);
    //     map.addLayer({
    //       id: layerId,
    //       type: 'symbol',
    //       source: layerId,
    //       layout: {
    //         visibility: MapLayerVisibility.VISIBLE,
    //         'icon-image': MapLayerIcon.PLAIN_VESSEL.BORDER,
    //         'icon-allow-overlap': true,
    //         'icon-rotation-alignment': 'map',
    //         'icon-rotate': ['number', ['get', 'course'], 360],
    //         'icon-size': [
    //           'interpolate',
    //           ['linear'],
    //           ['zoom'],
    //           // zoom is 5 (or less) -> icon will be half size
    //           5,
    //           0.25,
    //           // zoom is 10 (or greater) -> icon will be full size
    //           10,
    //           0.5,
    //         ],
    //       },
    //       metadata: MapLayerManager.groupLayerMetaData([
    //         vesselId,
    //         MapGroupLayer.RF_TRACKS,
    //         MapGroupLayer.HISTORY,
    //       ]),
    //     });
    //     setVesselFeatures(layerId, [data[data.length - 1]]); // default to the last element on the history, which should be the vessels current position.
    //   },
    //   getLayerKey: (vesselId: string) =>
    //     `${vesselId}_${MapGroupLayer.RF_TRACKS}`,
    //   groupLayer: MapGroupLayer.RF,
    // },
  };

  export const layerListMyFleetsPanel = {
    JOURNEYS: {
      addLayer: (
        layerId: string,
        data: HistoricVesselPoint[],
        colorId?: number
      ) => {
        addSimpleGeoJsonSource(layerId);

        const tenant = store.getState().user.idToken?.tenantId;

        MapLayerManager.AddLayerinGroups(
          {
            id: layerId,
            type: 'line',
            source: layerId,
            layout: {
              visibility: MapLayerVisibility.VISIBLE,
            },
            paint: MapLayerPaint.VESSEL_HISTORY_JOURNEY(colorId, tenant),
            metadata: MapLayerManager.groupLayerMetaData([
              MapGroupLayer.MYFLEET,
            ]),
          },
          [MapGroupLayer.MYFLEET]
        );
        setVesselJourney(data, layerId);
      },
      getLayerKey: (vesselId: string) =>
        `${vesselId}_${MapLayer.MY_FLEET_VESSELS_JOURNEYS}`,
      groupLayer: MapGroupLayer.MYFLEET,
    },
    // **********************************************************
    AIS_POSITIONS: {
      addLayer: (layerId: string, data: HistoricVesselPoint[]) => {
        const onClick = (e: MapTouchEvent | MapMouseEvent) => {
          const { idToken } = store.getState().user;
          // If no idToken, safer to proceed
          if (idToken) {
            const draw =
              themedDraws[(idToken.tenantId as Tenant) || TenantId.GEOLLECT];
            if (draw.getMode() !== 'simple_select') return;
          }
          AISVesselsController.layerEvents.onClick(e);
        };

        addSimpleGeoJsonSource(layerId);

        MapLayerManager.AddLayerinGroups(
          {
            id: layerId,
            type: 'symbol',
            source: layerId,
            minzoom: 7,
            layout: {
              visibility: MapLayerVisibility.VISIBLE,
              'icon-image': MapLayerIcon.HISTORIC_POSITION,
              'icon-rotation-alignment': 'map',
              'icon-rotate': ['number', ['get', 'course'], 360],
              'icon-size': [
                'interpolate',
                ['linear'],
                ['zoom'],
                // zoom is 5 (or less) -> icon will be half size
                5,
                0.5,
                // zoom is 10 (or greater) -> icon will be full size
                10,
                1,
              ],
            },
            metadata: MapLayerManager.groupLayerMetaData([
              MapGroupLayer.MYFLEET,
            ]),
          },
          [MapGroupLayer.MYFLEET]
        );

        setVesselFeatures(layerId, data);
        MapHelpers.onLayerVisibilityChange(layerId, () => {
          AISMergedPointsController.onAISPositionsLayerVisibilityChange(
            layerId
          );
        });

        MapHelpers.addMapEventListener(
          'mouseenter',
          layerId,
          AISVesselsController.layerEvents.onMouseEnter
        );

        MapHelpers.addMapEventListener(
          'mouseleave',
          layerId,
          AISVesselsController.layerEvents.onMouseLeave
        );

        MapHelpers.addMapEventListener('click', layerId, onClick);
        // prevent touch-then-drag being interpreted as a click
        // if start point is significantly different from end point, or 250ms elapses, then it's a drag
        let isClick = false;
        let point: { x: number; y: number } | null = null;

        MapHelpers.addMapEventListener('touchstart', layerId, (e) => {
          isClick = true;
          point = e.point;
          setTimeout(() => {
            isClick = false;
            point = null;
          }, 250);
        });

        MapHelpers.addMapEventListener('touchend', layerId, (e) => {
          if (isClick && GeoHelper.pointsNear(point, e.point)) onClick(e);
        });
      },
      getLayerKey: (vesselId: string) =>
        `${vesselId}_${MapLayer.MY_FLEET_VESSELS_AIS_POSITIONS}`,
      groupLayer: MapGroupLayer.MYFLEET,
    },
    SELECTED_POSITION: {
      addLayer: (
        layerId: string,
        data: HistoricVesselPoint[],
        _colorId?: number,
        userPreferences?: UserPreferences
      ) => {
        const { colour, size } = prepareVesselStylingData(userPreferences!);

        addSimpleGeoJsonSource(layerId);
        MapLayerManager.AddLayerinGroups(
          {
            ...GENERIC_VESSEL,
            id: layerId,
            source: layerId,
            layout: {
              ...GENERIC_VESSEL.layout,
              visibility: MapLayerVisibility.VISIBLE,
              'icon-image': MapLayerIcon.PLAIN_VESSEL.BORDER,
              'icon-size': size || GENERIC_VESSEL_SIZE,
            },
            paint: {
              ...GENERIC_VESSEL.paint,
              'icon-color': colour,
            },
          },
          [MapGroupLayer.MYFLEET]
        );
        setVesselFeatures(layerId, [data[data.length - 1]]); // default to the last element on the history, which should be the vessels current position.
      },
      getLayerKey: (vesselId: string) =>
        `${vesselId}_${MapLayer.MY_FLEET_SELECTED_AIS_POSITION}`,
      groupLayer: MapGroupLayer.MYFLEET,
    },
  };

  // list of layers that need to be drawn and displayed dynamically for Vessel History
  export const getLayerListSources = (
    historicVesselPoints: HistoricVesselPoint[]
  ) => {
    const uniqueVessels = getUniqueHistoricVessels(historicVesselPoints);
    const uniqueMmsis = getUniqueHistoricMmsis(historicVesselPoints);

    return {
      AIS_POSITIONS: uniqueVessels.map((vesselId) =>
        layerList.AIS_POSITIONS.getLayerKey(vesselId)
      ),
      AIS_HEATMAPS: uniqueVessels.map((vesselId) =>
        layerList.AIS_HEATMAPS.getLayerKey(vesselId)
      ),
      JOURNEYS: uniqueVessels.map((vesselId) =>
        layerList.JOURNEYS.getLayerKey(vesselId)
      ),
      VESSELS: uniqueVessels.map((vesselId) =>
        layerList.VESSELS.getLayerKey(vesselId)
      ),
      SELECTED_POSITION: uniqueVessels.map((vesselId) =>
        layerList.SELECTED_POSITION.getLayerKey(vesselId)
      ),
      AIS_DATA_GAPS: uniqueVessels.map((vesselId) =>
        layerList.AIS_DATA_GAPS.getLayerKey(vesselId)
      ),
      RF_TRACKS: uniqueMmsis.map((mmsi) =>
        mmsi
          ? RfEventController.layerList.RF_TRACKS.getLayerKey(String(mmsi))
          : ''
      ), // TODO this is a bit hacky and vessels without mmsis could break things
      RF_ESTIMATED_POSITIONS: uniqueMmsis.map((mmsi) =>
        mmsi
          ? RfEventController.layerList.RF_ESTIMATED_POSITIONS.getLayerKey(
              String(mmsi)
            )
          : ''
      ),
      RF_REPORTED_POSITIONS: uniqueMmsis.map((mmsi) =>
        mmsi
          ? RfEventController.layerList.RF_REPORTED_POSITIONS.getLayerKey(
              String(mmsi)
            )
          : ''
      ),
    };
  };

  export const clearAllHistoryLayers = () => {
    try {
      // delete all dynamic layers by their group layer id.
      Object.values(layerList).forEach((item) => {
        const existingLayers = MapLayerManager.findLayersByGroupLayerId(
          item.groupLayer
        );
        existingLayers.forEach((layer) => {
          MapLayerManager.destroyLayer(layer.id);
        });
      });

      RfEventController.clearRfEventLayers();
      AISDataGapsController.clearSelectedFeature();
      hideAISDataGapPointPopups();
      hideAISMergedPointsPopup();
    } catch (e) {
      // eslint-disable-next-line no-useless-return
      return;
    }
  };

  export const createAISDataGapsLayer = () => {
    const AIS_DATA_GAPS_LAYER_ID = MapGroupLayer.VESSEL_HISTORY_AIS_DATA_GAPS;

    if (!MapHelpers.getLayer(AIS_DATA_GAPS_LAYER_ID)) {
      addSimpleGeoJsonSource(AIS_DATA_GAPS_LAYER_ID);
      MapHelpers.addLayer({
        id: AIS_DATA_GAPS_LAYER_ID,
        type: 'line',
        source: AIS_DATA_GAPS_LAYER_ID,
        layout: {
          visibility: MapLayerVisibility.VISIBLE,
        },
        paint: MapLayerPaint.HIGHLIGHTED_LINES,
        metadata: MapLayerManager.groupLayerMetaData([
          MapGroupLayer.VESSEL_HISTORY_AIS_POSITIONS,
          MapGroupLayer.HISTORY,
        ]),
      });
    }

    MapHelpers.addMapEventListener('mouseenter', AIS_DATA_GAPS_LAYER_ID, (e) =>
      AISDataGapsController.layerEvents.onMouseEnter(e)
    );

    MapHelpers.addMapEventListener('mouseleave', AIS_DATA_GAPS_LAYER_ID, (e) =>
      AISDataGapsController.layerEvents.onMouseLeave(e)
    );

    MapHelpers.addMapEventListener('click', AIS_DATA_GAPS_LAYER_ID, (e) =>
      AISDataGapsController.layerEvents.onClick(e)
    );

    MapHelpers.onLayerVisibilityChange(
      AIS_DATA_GAPS_LAYER_ID,
      AISDataGapsController.onAISDataGapsLayerVisibilityChange
    );

    // register the event for de-selecting a feature only when popup is open
    // this should prevent duplicates/unnecessary popup events being called
    toPopup.on('open', () =>
      toPopup.once('close', AISDataGapsController.addPopupEventListeners)
    );
  };

  export const init = (
    formattedVesselPoints: HistoricVesselPoint[],
    userPreferences: UserPreferences
  ) => {
    const { historicVesselPoints } = store.getState().history;
    const uniqueVessels = getUniqueHistoricVessels(formattedVesselPoints);

    // build layers from layer list dynamically
    uniqueVessels.forEach((vesselId: string) => {
      const filteredPoints = historicVesselPoints.filter(
        (point) => point.vessel_id === vesselId
      );

      Object.values(layerList).forEach((item) => {
        const { addLayer } = item;
        addLayer(vesselId, filteredPoints, userPreferences);
      });
    });

    createAISDataGapsLayer();
    AISMergedPointsController.init();
  };

  export const onVesselHistoryDraw = () => {
    const { historicVesselPoints } = store.getState().history;

    if (!historicVesselPoints.length) return;

    const geojson = GeoHelper.createGeoJSON(
      GeoHelper.vesselsToGeoJSONFeatures(historicVesselPoints)
    );

    MapHelpers.zoomToFeatureCollection(geojson);
  };

  export const createHistoricalLocationLayers = (
    historicVesselPoints: HistoricVesselPoint[],
    colorId?: number,
    userPreferences?: UserPreferences
  ) => {
    const uniqueVessels = getUniqueHistoricVessels(historicVesselPoints);
    const layerKeys = ['JOURNEYS', 'AIS_POSITIONS', 'SELECTED_POSITION'];

    const filteredLayerList = Object.fromEntries(
      Object.entries(layerListMyFleetsPanel).filter(([key]) =>
        layerKeys.includes(key)
      )
    );

    // build journey and ais position layers
    uniqueVessels.forEach((vesselId: string, vesselIndex) => {
      const filteredPoints = historicVesselPoints.filter(
        (point) => point.vessel_id === vesselId
      );

      Object.values(filteredLayerList)
        .filter((item: any) => 'addLayer' in item && 'getLayerKey' in item)
        .forEach((item) => {
          const { addLayer, getLayerKey } = item;
          addLayer(
            getLayerKey(vesselId),
            filteredPoints,
            colorId ?? vesselIndex,
            userPreferences
          );
          MapHelpers.moveLayerIfExists(
            getLayerKey(vesselId),
            MapLayer.MY_FLEET_VESSELS
          );
        });
    });
  };

  export const clearHistoricalLocationLayers = () => {
    const layerKeys = ['JOURNEYS', 'AIS_POSITIONS', 'SELECTED_POSITION'];

    const filteredLayerList = Object.fromEntries(
      Object.entries(layerListMyFleetsPanel).filter(([key]) =>
        layerKeys.includes(key)
      )
    );

    Object.values(filteredLayerList).forEach((item) => {
      const existingLayers = MapLayerManager.findLayersByGroupLayerId(
        item.groupLayer
      );
      existingLayers.forEach((layer) => MapLayerManager.destroyLayer(layer.id));
    });

    AISDataGapsController.clearSelectedFeature();
    hideAISDataGapPointPopups();
    hideAISMergedPointsPopup();
  };
}

export default VesselHistoryController;
