import geoFavicon from './assets/geollect-favicon-32x32.png';
import geoLogo from './assets/geollect-logo.png';
import riFavicon from './assets/ri-favicon.png';
import riLogo from './assets/ri-logo.png';
import MapStyle from './map/map-style';
import { setStyle } from './map/map.slice';
import store from './store';
import TenantId from './tenant';

export type Tenant = keyof typeof TenantId;

export interface ColourScheme {
  sarTint: string;
  areaTint: string;
  drawActive: string;
  drawInactive: string;
  drawStatic: string;
}

interface ThemeItem {
  image: string;
  title: string;
  icon: string;
  mapboxStyle: string;
  colourScheme: string;
}

const geollectColours: ColourScheme = {
  sarTint: '#007BB8',
  areaTint: '#ffac1c',
  drawActive: '#fbb03b',
  drawInactive: '#3bb2d0',
  drawStatic: '#404040',
};

const riskIntelligenceColours: ColourScheme = {
  sarTint: '#007BB8',
  areaTint: '#203058',
  drawActive: '#3C5AA6',
  drawInactive: '#6D7DA6',
  drawStatic: '#404040',
};

export const colourSchemes: Map<string, ColourScheme> = new Map([
  [TenantId.GEOLLECT, geollectColours],
  [TenantId.RISK_INTELLIGENCE, riskIntelligenceColours],
]);

const themeVariables: Record<Tenant, ThemeItem> = {
  GEOLLECT: {
    image: geoLogo,
    title: 'Geollect',
    icon: geoFavicon,
    mapboxStyle: MapStyle.DEFAULT,
    colourScheme: TenantId.GEOLLECT,
  },
  RISK_INTELLIGENCE: {
    image: riLogo,
    title: 'Risk Intelligence',
    icon: riFavicon,
    mapboxStyle: MapStyle.RISK_INTELLIGENCE,
    colourScheme: TenantId.RISK_INTELLIGENCE,
  },
};

export const getFromTheme = (
  theme: Tenant,
  key: keyof ThemeItem,
  fallback?: string
) => themeVariables[theme][key] || fallback || '';

const updateFavicon = (icon: string) => {
  let favicon: HTMLLinkElement | null =
    document.querySelector("link[rel~='icon']");
  if (!favicon) {
    favicon = document.createElement('link');
    favicon.rel = 'icon';
    document.getElementsByTagName('head')[0].appendChild(favicon);
  }
  favicon.href = icon;
};

export const setTheme = (theme: Tenant) => {
  const { title, mapboxStyle, icon } = themeVariables[theme];

  document.querySelector('html')?.setAttribute('data-theme', theme);
  document.title = title;
  updateFavicon(icon);
  store.dispatch(setStyle(mapboxStyle));
};

export const getDefaultMapboxStyle = () => {
  const { idToken } = store.getState().user;
  const tenant = idToken?.tenantId;

  return getFromTheme(
    tenant as Tenant,
    'mapboxStyle',
    themeVariables.GEOLLECT.mapboxStyle
  );
};
