import { CompanyPermissionToggles } from '../../admin/admin-page/admin.slice';
import { EAlertTypes } from '../../models/alerts/alert-configuration';
import VesselTypes from '../../models/vessels/vessel-types.model';
import store from '../../store';
import TenantId from '../../tenant';
import { Token, UserFeatureFlags } from '../../user/user.slice';
import { UserRole } from '../../utils/user.enum';
import {
  COMPANIES_ALLOWED_ACCESS_TO_ADTECH_POC,
  COMPANIES_ALLOWED_ACCESS_TO_CAPELLA,
  COMPANIES_ALLOWED_ACCESS_TO_ESA,
  COMPANIES_ALLOWED_ACCESS_TO_FLEETS,
  COMPANIES_ALLOWED_ACCESS_TO_GLOBAL_AIS,
  COMPANIES_ALLOWED_ACCESS_TO_HISTORIC_VESSELS,
  COMPANIES_ALLOWED_ACCESS_TO_SUBSEA_INFRASTRUCTURE_POC,
  COMPANIES_ALLOWED_ACCESS_TO_UNSEEN_LABS,
} from './access-control-constants';
import { getReleaseToggles, ReleaseToggles } from './release-toggles';

export type Privileges = {
  canAccessAlerts: Record<EAlertTypes, boolean>;
  canAccessVesselTypes: Record<VesselTypes, boolean>;
  canAccessIncidents: boolean;
  canAccessVessels: {
    ais: boolean;
    ri: boolean;
  };
  canAccessFleets: boolean;
  canAccessPorts: {
    wpi: boolean;
    land: boolean;
    coastal: boolean;
    cruise: boolean;
  };
  canAccessRoutes: boolean;
  canAccessHistory: boolean;
  canAccessBoundaries: boolean;
  canAccessDrawings: boolean;
  canAccessMaps: boolean;
  canAccessSanctions: boolean;
  canAccessRiMaritimeAreas: boolean;
  canAccessTimeline: boolean;
  canAccessMapTools: boolean;
  canAccessGraphs: boolean;
  canAccessNearbyVessels: boolean;
  canAccessHistoricVessels: boolean;
  canAccessWeather: boolean;
  canAccessSharedDrawings: boolean;
  canAccessRfData: boolean;
  canAccessShippingLanes: boolean;
  canMultiSelect: boolean;
  canAccessRiBoundaries: boolean;
  canAccessSpireRF: boolean;
  canAccessHelp: boolean;
  canAccessDidYouKnow: boolean;
  canAccessIncidentsDateRangeIndicator: boolean;
  canAccessBathymetry: boolean;
  canAccessDatumRings: boolean;
  canAccessAdtechData: boolean;
  canAccessVesselsV2: boolean;
  canUploadRTZ: boolean;
  canAccessIncidentZoom: boolean;
  canDrawPoints: boolean;
  canAccessPortHeadline: boolean;
  canAccessVesselPrefs: boolean;
  canAccessHistoryWebSocket: boolean;
  canAccessHighTierHistoryWS: boolean;
  canAccessAvcsCharts: boolean;
  canAccessSubseaInfrastructure: boolean;
  canAccessFathom: boolean;
  canAccessDrawingsOptions: boolean;
  canAccessEsa: boolean;
  canAccessMapLayerHandler: boolean;
  canAccessGlobalAis: boolean;
  canAccessMergeStationaryPoints: boolean;
  canAccessCapella: boolean;
  canAccessUnseenLabs: boolean;
  canAccessPermissionToggles: boolean;
};

const getCanAccessPermissionToggles = (idToken: Token) => {
  const releaseToggles = getReleaseToggles();

  // Can't import the existing isAdmin function from `user.utils.tsx`, as it would create a circular dependency.
  const isAdminUser = [UserRole.GLOBAL_ADMIN, UserRole.COMPANY_ADMIN].some(
    (role) => store.getState().user.idToken?.groups?.includes(role)
  );

  const canAccessPermissionToggles =
    idToken?.tenantId === TenantId.GEOLLECT &&
    isAdminUser &&
    releaseToggles.adminPermissionsTogglesEnabled;

  return canAccessPermissionToggles;
};

const NO_PRIVILEGES: Privileges = {
  canAccessAlerts: {
    AIS_OFF: false,
    AIS_ON: false,
    ENTER_PORT: false,
    ENTER_ROI: false,
    EXIT_ROI: false,
    INCIDENTS: false,
    INCIDENTS_ROUTE: false,
    DEP_PORT: false,
    STS: false,
    STATIC_CHANGE: false,
  },
  canAccessVesselTypes: {
    DRY_BULK: false,
    GENERAL_CARGO: false,
    CONTAINER: false,
    REEFER: false,
    ROLL_ON_ROLL_OFF: false,
    OFFSHORE: false,
    HIGH_SPEED_CRAFT: false,
    CAR_CARRIER: false,
    VEHICLE_PASSENGER: false,
    PLEASURE_CRAFT: false,
    FISHING: false,
    GENERAL_TANKER: false,
    GAS_TANKER: false,
    TANKER_PRODUCT: false,
    PASSENGER: false,
    OTHER_UNKNOWN: false,
  },
  canAccessIncidents: false,
  canAccessVessels: {
    ais: false,
    ri: false,
  },
  canAccessFleets: false,
  canAccessPorts: {
    wpi: false,
    land: false,
    coastal: false,
    cruise: false,
  },
  canAccessRoutes: false,
  canAccessHistory: false,
  canAccessBoundaries: false,
  canAccessDrawings: false,
  canAccessMaps: false,
  canAccessSanctions: false,
  canAccessRiMaritimeAreas: false,
  canAccessTimeline: false,
  canAccessMapTools: false,
  canAccessGraphs: false,
  canAccessNearbyVessels: false,
  canAccessHistoricVessels: false,
  canAccessWeather: false,
  canAccessSharedDrawings: false,
  canAccessRfData: false,
  canAccessShippingLanes: false,
  canMultiSelect: false,
  canAccessRiBoundaries: false,
  canAccessSpireRF: false,
  canAccessHelp: false,
  canAccessDidYouKnow: false,
  canAccessIncidentsDateRangeIndicator: false,
  canAccessBathymetry: false,
  canAccessDatumRings: false,
  canAccessAdtechData: false,
  canAccessVesselsV2: false,
  canUploadRTZ: false,
  canAccessIncidentZoom: false,
  canDrawPoints: false,
  canAccessPortHeadline: false,
  canAccessVesselPrefs: false,
  canAccessHistoryWebSocket: false,
  canAccessHighTierHistoryWS: false,
  canAccessSubseaInfrastructure: false,
  canAccessAvcsCharts: false,
  canAccessFathom: false,
  canAccessDrawingsOptions: false,
  canAccessEsa: false,
  canAccessMapLayerHandler: false,
  canAccessGlobalAis: false,
  canAccessMergeStationaryPoints: false,
  canAccessCapella: false,
  canAccessUnseenLabs: false,
  canAccessPermissionToggles: false,
};

type GeollectPermissionTogglePrivilegesArgs = {
  releaseToggles: ReleaseToggles;
  companyPermissionToggles: CompanyPermissionToggles;
  userFeatureFlags: UserFeatureFlags | null;
  idToken: Token;
};

export const geollectPrivilegesPermissionsTogglesV1 = ({
  companyPermissionToggles,
  releaseToggles,
  userFeatureFlags,
  idToken,
}: GeollectPermissionTogglePrivilegesArgs): Privileges => ({
  ...NO_PRIVILEGES,
  canAccessAvcsCharts:
    releaseToggles.avcsEnabled &&
    !!companyPermissionToggles.avcs &&
    !!userFeatureFlags?.avcs,
  canAccessPermissionToggles: getCanAccessPermissionToggles(idToken),
});

type GeollectPrivilegesArgs = {
  releaseToggles: ReleaseToggles;
  userFeatureFlags: UserFeatureFlags | null;
  idToken: Token;
};

export const geollectPrivileges = ({
  idToken,
  releaseToggles,
  userFeatureFlags,
}: GeollectPrivilegesArgs): Privileges => ({
  ...NO_PRIVILEGES,
  canAccessAlerts: {
    AIS_OFF: true,
    AIS_ON: true,
    ENTER_PORT: true,
    ENTER_ROI: true,
    EXIT_ROI: true,
    INCIDENTS: true,
    INCIDENTS_ROUTE: true,
    DEP_PORT: true,
    STS: true,
    STATIC_CHANGE: true,
  },
  canAccessVesselTypes: {
    DRY_BULK: true,
    GENERAL_CARGO: true,
    CONTAINER: true,
    REEFER: true,
    ROLL_ON_ROLL_OFF: true,
    OFFSHORE: true,
    HIGH_SPEED_CRAFT: true,
    CAR_CARRIER: true,
    VEHICLE_PASSENGER: true,
    PLEASURE_CRAFT: true,
    FISHING: true,
    GENERAL_TANKER: true,
    GAS_TANKER: true,
    TANKER_PRODUCT: true,
    PASSENGER: true,
    OTHER_UNKNOWN: true,
  },
  canAccessIncidents: true,
  canAccessVessels: {
    ais: true,
    ri: false,
  },
  canAccessFleets: idToken.companyId
    ? COMPANIES_ALLOWED_ACCESS_TO_FLEETS.includes(idToken.companyId)
    : false,
  canAccessPorts: {
    wpi: true,
    land: true,
    coastal: true,
    cruise: true,
  },
  canAccessRoutes: true,
  canAccessHistory: true,
  canAccessBoundaries: true,
  canAccessDrawings: true,
  canAccessMaps: true,
  canAccessSanctions: true,
  canAccessRiMaritimeAreas: true,
  canAccessTimeline: true,
  canAccessMapTools: true,
  canAccessGraphs: true,
  canAccessNearbyVessels: true,
  canAccessHistoricVessels: idToken.companyId
    ? COMPANIES_ALLOWED_ACCESS_TO_HISTORIC_VESSELS.includes(idToken.companyId)
    : false,
  canAccessWeather: true,
  canAccessSharedDrawings: true,
  canAccessRfData: releaseToggles.rfDataEnabled,
  canAccessShippingLanes: true,
  canMultiSelect: true,
  canAccessCapella: idToken.companyId
    ? COMPANIES_ALLOWED_ACCESS_TO_CAPELLA.includes(idToken.companyId)
    : false,
  canAccessUnseenLabs: idToken.companyId
    ? COMPANIES_ALLOWED_ACCESS_TO_UNSEEN_LABS.includes(idToken.companyId)
    : false,
  canAccessSpireRF: false,
  canAccessBathymetry: true,
  canAccessDatumRings: false,
  canAccessAdtechData: idToken.companyId
    ? COMPANIES_ALLOWED_ACCESS_TO_ADTECH_POC.includes(idToken.companyId)
    : false,
  canAccessVesselsV2: releaseToggles.vesselsV2Enabled,
  canUploadRTZ: releaseToggles.rtzUploadEnabled,
  canAccessIncidentZoom: true,
  canDrawPoints: true,
  canAccessVesselPrefs: true,
  canAccessPortHeadline: true,
  canAccessHistoryWebSocket: true,
  canAccessHighTierHistoryWS: true,
  canAccessSubseaInfrastructure: idToken.companyId
    ? COMPANIES_ALLOWED_ACCESS_TO_SUBSEA_INFRASTRUCTURE_POC.includes(
        idToken.companyId
      )
    : false,
  canAccessAvcsCharts:
    releaseToggles.avcsEnabled && (userFeatureFlags?.avcs || false),
  canAccessFathom: true,
  canAccessDrawingsOptions: true,
  canAccessEsa: idToken.companyId
    ? COMPANIES_ALLOWED_ACCESS_TO_ESA.includes(idToken.companyId)
    : false,
  canAccessMapLayerHandler: true,
  canAccessGlobalAis:
    releaseToggles.globalAisEnabled &&
    !!idToken.companyId &&
    COMPANIES_ALLOWED_ACCESS_TO_GLOBAL_AIS.includes(idToken.companyId),
  canAccessMergeStationaryPoints: releaseToggles.mergeStationaryPointsEnabled,
  canAccessPermissionToggles: getCanAccessPermissionToggles(idToken),
});

export const riskIntelligencePrivileges = (
  userFeatureFlags: UserFeatureFlags | null
): Privileges => ({
  ...NO_PRIVILEGES,
  canAccessAlerts: {
    ...NO_PRIVILEGES.canAccessAlerts,
    ENTER_ROI: userFeatureFlags?.fleet_license || false,
    INCIDENTS: userFeatureFlags?.fleet_license || false,
    INCIDENTS_ROUTE: userFeatureFlags?.voyager_intel_tool || false,
  },
  canAccessVesselTypes: {
    ...NO_PRIVILEGES.canAccessVesselTypes,
  },
  canAccessIncidents: true,
  canAccessVessels: {
    ais: userFeatureFlags?.fleet_license || false,
    ri: true,
  },
  canAccessFleets: false,
  canAccessPorts: {
    wpi:
      userFeatureFlags?.ports_license ||
      userFeatureFlags?.cruise_license ||
      false,
    land:
      userFeatureFlags?.ports_license ||
      userFeatureFlags?.cruise_license ||
      false,
    coastal:
      userFeatureFlags?.ports_license ||
      userFeatureFlags?.cruise_license ||
      false,
    cruise:
      (userFeatureFlags?.ports_license && userFeatureFlags?.cruise_license) ||
      false,
  },
  canAccessRoutes: userFeatureFlags?.voyager_intel_tool || false,
  canAccessHistory: userFeatureFlags?.ais_history || false,
  canAccessBoundaries: true,
  canAccessDrawings: true,
  canAccessMaps: true,
  canAccessSanctions: userFeatureFlags?.sanctions || false,
  canAccessRiMaritimeAreas: true,
  canAccessRiBoundaries: true,
  canAccessHelp: true,
  canAccessDidYouKnow: true,
  canAccessIncidentsDateRangeIndicator: true,
  canAccessBathymetry: false,
  canAccessDatumRings: true,
  canAccessVesselsV2: true,
  canUploadRTZ: true,
  canAccessIncidentZoom: true,
  canAccessVesselPrefs: true,
  canAccessPortHeadline: true,
  canAccessHistoryWebSocket: true,
  canAccessFathom: true,
});

type CalculatePrivilegesArgs = {
  idToken: Token | null;
  userFeatureFlags: UserFeatureFlags | null;
  companyPermissionToggles: CompanyPermissionToggles;
};

export const calculatePrivileges = ({
  idToken,
  companyPermissionToggles,
  userFeatureFlags,
}: CalculatePrivilegesArgs) => {
  if (!idToken?.tenantId) return NO_PRIVILEGES;

  const releaseToggles = getReleaseToggles();

  const privileges: Record<TenantId, Privileges> = {
    [TenantId.GEOLLECT]: releaseToggles.permissionsTogglesEnabled
      ? geollectPrivilegesPermissionsTogglesV1({
          companyPermissionToggles,
          releaseToggles,
          userFeatureFlags,
          idToken,
        })
      : geollectPrivileges({
          idToken,
          releaseToggles,
          userFeatureFlags,
        }),
    [TenantId.RISK_INTELLIGENCE]: riskIntelligencePrivileges(userFeatureFlags),
  };

  return privileges[idToken.tenantId];
};
