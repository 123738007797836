import { Expression } from 'mapbox-gl';
import { VesselSource } from '../models/vessels/vessel.model';
import {
  UserPreferences,
  VesselPrefColour,
  VesselPrefLabel,
  VesselPrefSize,
} from '../user-settings/user-preferences/user-preferences.slice';
import {
  GENERIC_VESSEL_SIZE,
  VESSEL_COLOUR_GREEN,
  VESSEL_COLOUR_ORANGE,
  VESSEL_COLOUR_WHITE,
} from './vessel-constants.utils';

export const prepareVesselColourData = (preferences: UserPreferences) => {
  const vesselColour =
    preferences?.vessel_preference?.vesselColour ?? VESSEL_COLOUR_WHITE;

  const preferredColour =
    vesselColour === VesselPrefColour.ORANGE
      ? VESSEL_COLOUR_ORANGE
      : VESSEL_COLOUR_WHITE;

  const iconColour: Expression = [
    'match',
    ['get', 'source'],
    VesselSource.AIS,
    preferredColour,
    VesselSource.RISK_INTELLIGENCE,
    VESSEL_COLOUR_GREEN,
    preferredColour,
  ];

  return iconColour;
};

const prepareVesselStylingData = (preferences: UserPreferences) => {
  const { vesselLabel, vesselSize } = preferences.vessel_preference;

  const label: Expression | string =
    vesselLabel === VesselPrefLabel.SHOW ? ['get', 'name'] : '';
  const sizeMultiplier = vesselSize === VesselPrefSize.LARGE ? 1.25 : 1;

  const size: Expression = [...GENERIC_VESSEL_SIZE];
  size[6] *= sizeMultiplier;
  size[8] *= sizeMultiplier;
  size[10] *= sizeMultiplier;

  const colour = prepareVesselColourData(preferences);

  return {
    label,
    size,
    colour,
  };
};

export default prepareVesselStylingData;
