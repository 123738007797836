import {
  CategorisedCompanyPermissionToggles,
  CompanyPermissionToggles,
} from '../admin/admin-page/admin.slice';
import store from '../store';

type ToggleCategoryConfig = Record<
  string,
  Array<keyof CompanyPermissionToggles>
>;

/**
 * TO DO: add toggle categories in as we work through their respective tickets.
 *
 * Expected format:
 *
 * {
 *  'Category Name 1': ['adtech_data', 'ais_on_off', ...],
 *  'Category Name 2': ['esa_maps', 'global_ais', ...],
 *   ...others
 * }
 */
const TOGGLE_CATEGORIES: ToggleCategoryConfig = {};

const defaultPermissionToggles: CompanyPermissionToggles = {
  adtech_data: { enabled: false },
  ais_on_off: { enabled: false },
  ais_spoofing: { enabled: false },
  aoi_enter_exit: { enabled: false },
  avcs: { enabled: false },
  bathymetry: { enabled: false },
  boundaries: { enabled: false },
  discuss: { enabled: false },
  drawings: { enabled: false },
  esa_maps: { enabled: false },
  fleets: { enabled: false },
  global_ais: { enabled: false },
  history_all_periods: { enabled: false },
  history_specific_period: { enabled: false },
  incidents_on_route: { enabled: false },
  measuring_tool: { enabled: false },
  nearby_vessel: { enabled: false },
  opensea_maps: { enabled: false },
  port_enter_exit: { enabled: false },
  position_validation: { enabled: false },
  radio_frequency_data: { enabled: false },
  range_rings: { enabled: false },
  ri_areas: { enabled: false },
  ri_incidents: { enabled: false },
  ri_ports: { enabled: false },
  ri_threat_assessments: { enabled: false },
  routes: { enabled: false },
  sar_imagery: { enabled: false },
  search_by_area: { enabled: false },
  shipping_lanes: { enabled: false },
  static_changes: { enabled: false },
  sts: { enabled: false },
  subsea_infrastructure: { enabled: false },
  weather: { enabled: false },
  wpi_ports: { enabled: false },
};

/**
 * Gets the permission toggles for the currently selected company.
 *
 * @returns {CompanyPermissionToggles} The permission toggles for the currently selected company.
 */
export const getCompanyPermissionToggles = () => {
  const companyPermissionToggles =
    store.getState().admin.selectedCompany?.permission_toggles;

  if (!companyPermissionToggles) return defaultPermissionToggles;

  const updatedPermissionToggles = Object.keys(defaultPermissionToggles).reduce(
    (acc, curr) => {
      const toggle =
        companyPermissionToggles[curr as keyof CompanyPermissionToggles];

      return { ...acc, [curr]: { enabled: toggle.enabled } };
    },
    defaultPermissionToggles
  );

  return updatedPermissionToggles;
};

/**
 * Takes a permission toggle object and categorises it by feature.
 *
 * @param {CompanyPermissionToggles} companyPermissionToggles The permission toggles for the currently selected company.
 *
 * @returns {CategorisedCompanyPermissionToggles} The permission toggles for the currently selected company, categorised by feature.
 */
export const createCategorisedPermissionToggles = (
  companyPermissionToggles: CompanyPermissionToggles
) => {
  const categorisedPermissionToggles: CategorisedCompanyPermissionToggles =
    Object.entries(TOGGLE_CATEGORIES).reduce((acc, curr) => {
      const [category, toggles] = curr;

      return {
        ...acc,
        [category]: toggles.reduce((accToggles, toggle) => {
          const isEnabled =
            companyPermissionToggles[toggle as keyof CompanyPermissionToggles]
              .enabled;

          return { ...accToggles, [toggle]: { enabled: isEnabled } };
        }, {}),
      };
    }, {});

  return categorisedPermissionToggles;
};
