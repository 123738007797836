/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface Vessel {
  imo: string;
  mmsi: string;
}

interface PresetValuesState {
  presetVessels: Vessel[];
  isCreateFleetsDialogOpen: boolean;
}

export const INITIAL_FLEETS_PANEL_STATE: PresetValuesState = {
  presetVessels: [],
  isCreateFleetsDialogOpen: false,
};

const FleetsPanelSlice = createSlice({
  name: 'fleetsPanel',
  initialState: INITIAL_FLEETS_PANEL_STATE,
  reducers: {
    setPresetVessels(state, action: PayloadAction<Vessel[]>) {
      state.presetVessels = action.payload;
    },
    setIsCreateFleetsDialogOpen(state, action: PayloadAction<boolean>) {
      state.isCreateFleetsDialogOpen = action.payload;
    },
  },
});

export const { setPresetVessels, setIsCreateFleetsDialogOpen } =
  FleetsPanelSlice.actions;

export default FleetsPanelSlice.reducer;
