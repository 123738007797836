import { Auth } from '@aws-amplify/auth';
import { Heading } from '@aws-amplify/ui-react';
import { Box, Typography } from '@mui/material';
import classNames from 'classnames';
import React, { useCallback, useEffect, useRef } from 'react';
import { getUserProperties } from './api';
import withAuthenticator from './auth-provider';
import LoadingScreen from './common-components/loading-screen/loading-screen';
import { useAppDispatch, useAppSelector, useMobile } from './hooks';
import { MapExtent } from './map/map.slice';
import MapHelpers from './map/map.utils';
import './require-auth.scss';
import { RESET_ACTION } from './store';
import {
  setApplySetupBarError,
  setApplySetupBarNumber,
} from './user-settings/apply-setup-bar/apply-setup-bar.slice';
import {
  DEFAULT_USER_PREFERENCES,
  VesselPrefColour,
  VesselPrefLabel,
  VesselPrefSize,
  setMapSetupLoaded,
  setUserPreferences,
  setUserPreferencesLoading,
} from './user-settings/user-preferences/user-preferences.slice';
import {
  setShowLiteModePopup,
  setUserFeatureFlags,
  setUserIdToken,
  setUserIdTokenError,
} from './user/user.slice';
import loadLiteMode from './utils/lite-mode.utils';
import { UserPreferencesBaseMap } from './utils/user.enum';
import {
  loadMapSavedSetup,
  setDefaultFiltersForUser,
} from './utils/user.utils';
import useAccessControl from './hooks/access-control/useAccessControl';

type RequireAuthProps = {
  children: React.ReactNode;
};

function RequireAuth({ children }: RequireAuthProps) {
  const mapInitialised = useAppSelector((state) => state.map.mapInitialised);
  const userPreferences = useAppSelector(
    (state) => state.userPreferences.userPreferences
  );
  const userPreferencesLoading = useAppSelector(
    (state) => state.userPreferences.userPreferencesLoading
  );
  const mapSetupLoaded = useAppSelector(
    (state) => state.userPreferences.mapSetupLoaded
  );
  const liteMode = useAppSelector((state) => state.user.liteMode);
  const userSigningOut = useAppSelector((state) => state.user.userSigningOut);
  const idToken = useAppSelector((state) => state.user.idToken);
  const logoutEventReceived = useAppSelector(
    (state) => state.webSocketEvents.logoutEventReceived
  );

  const cognitoId = useAppSelector((state) => state.user.idToken?.sub);
  const dispatch = useAppDispatch();
  const privileges = useAccessControl();

  const liteModeTimer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const signOut = async () => {
    await Auth.signOut();
    dispatch(RESET_ACTION);
  };

  useEffect(() => {
    if (logoutEventReceived || userSigningOut) {
      signOut();
    }
  }, [logoutEventReceived, userSigningOut]);

  const checkTokensRevoked = async () => {
    try {
      // Bypass the local cache to check whether the current tokens have been revoked in Cognito
      // If they have been revoked, this will throw an error.
      await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });
    } catch (error) {
      // Clear local tokens
      await signOut();
    }
  };

  const fetchUserIdToken = async () => {
    try {
      const session = await Auth.currentSession();
      const token = session.getIdToken().payload;

      // NB For Testing Tenant Themes/Styling use this:
      // dispatch(setUserIdToken(({ ...token, 'custom:tenantId': TENANT_ID_HERE })));
      // example: dispatch(setUserIdToken(({ ...token, 'custom:tenantId': TenantId.RISK_INTELLIGENCE })));
      dispatch(setUserIdToken(token));
    } catch (error) {
      dispatch(setUserIdTokenError(error));
    }
  };

  const loadUserPreferencesAndFeatureFlags = useCallback(async () => {
    try {
      if (cognitoId) {
        const preferencesResponse = await getUserProperties(cognitoId);
        const featureFlags = preferencesResponse[0].feature_flags;
        const preferences = preferencesResponse[0].user_preferences;

        MapHelpers.updateMapStyle(
          (preferences?.basemap as UserPreferencesBaseMap) ||
            UserPreferencesBaseMap.DEFAULT
        );
        MapHelpers.updateMapExtent(
          (preferences?.mapExtent as MapExtent) ||
            DEFAULT_USER_PREFERENCES.mapExtent
        );

        if (idToken?.tenantId) setDefaultFiltersForUser(idToken?.tenantId);
        dispatch(setUserFeatureFlags(featureFlags));
        dispatch(
          setUserPreferences({
            // we default global ais to on unless specifically set to off
            // this prevents requirement of data migration to add this to new users
            globalAisPanel: DEFAULT_USER_PREFERENCES.globalAisPanel,
            ...preferences,
            vessel_preference: preferences?.vessel_preference ?? {
              vesselLabel: VesselPrefLabel.HIDE,
              vesselSize: VesselPrefSize.STANDARD,
              vesselColour: VesselPrefColour.WHITE,
            },
            zoomToIncident:
              preferences?.zoomToIncident !== undefined
                ? preferences.zoomToIncident
                : true,
            basemap: preferences?.basemap ?? UserPreferencesBaseMap.DEFAULT,
          })
        );
      }
    } catch (error) {
      dispatch(setApplySetupBarError(true));

      dispatch(setUserPreferences(DEFAULT_USER_PREFERENCES));
    } finally {
      dispatch(setUserPreferencesLoading(false));
    }
  }, [cognitoId]);

  const clearLiteModeTimer = () => clearTimeout(liteModeTimer?.current!);

  useEffect(() => {
    (async () => {
      await checkTokensRevoked();
      await fetchUserIdToken();
    })();
  }, []);

  useEffect(() => {
    if (mapInitialised) {
      let loader: Promise<any> | undefined;
      if (liteMode) {
        loader = loadLiteMode(dispatch).then(() =>
          dispatch(setApplySetupBarNumber(0))
        );
      } else if (
        !userPreferencesLoading &&
        !mapSetupLoaded &&
        !!idToken // must check that an idToken exists - stops attempting to load map setup before user is logged in
      ) {
        loader = loadMapSavedSetup(userPreferences, dispatch, privileges).then(
          () => clearLiteModeTimer()
        );
      }

      if (loader) {
        loader.then(() => dispatch(setMapSetupLoaded(true)));
      }
    }
  }, [
    !userPreferencesLoading,
    mapInitialised,
    userPreferences,
    mapSetupLoaded,
    liteMode,
    idToken,
  ]);

  useEffect(() => {
    dispatch(setUserPreferencesLoading(true));

    if (idToken?.tenantId) {
      loadUserPreferencesAndFeatureFlags();
    }
  }, [loadUserPreferencesAndFeatureFlags]);

  useEffect(() => {
    if (mapInitialised && !liteMode) {
      liteModeTimer.current = setTimeout(
        () => dispatch(setShowLiteModePopup(true)),
        10000
      ); // show popup after 10 seconds
    }

    return () => {
      clearLiteModeTimer();
    };
  }, [mapInitialised, liteMode]);

  const isLoaded = idToken && userPreferences && !userPreferencesLoading;

  return (
    <>
      {!isLoaded && <LoadingScreen />}
      {isLoaded && children}
    </>
  );
}

function AuthHeader() {
  const isMobile = useMobile();

  return (
    <Box
      className={classNames('auth-header', {
        mobile: isMobile,
      })}
    />
  );
}

export default withAuthenticator(RequireAuth, {
  className: 'login-container',
  formFields: {
    signIn: {
      username: {
        label: undefined,
        placeholder: 'Username',
        required: true,
        type: 'text',
      },
      password: {
        label: undefined,
        placeholder: 'Password',
        required: true,
        type: 'password',
      },
    },
  },
  components: {
    Header: () => <AuthHeader />,
    SignIn: {
      Header: () => (
        <Heading>
          <Typography
            style={{
              fontFamily: 'Roboto',
              fontSize: '24px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '32px',
            }}
          >
            Sign In
          </Typography>
        </Heading>
      ),
    },
  },
  hideSignUp: true,
  initialState: 'signIn',
  passwordSettings: {
    passwordPolicyMinLength: 10,
    passwordPolicyCharacters: [
      'REQUIRES_LOWERCASE',
      'REQUIRES_NUMBERS',
      'REQUIRES_SYMBOLS',
      'REQUIRES_UPPERCASE',
    ],
  },
  services: undefined,
  signUpAttributes: [],
  socialProviders: [],
  variation: undefined,
});
