import { VesselHistoryResponse, getVesselHistory } from '../api';
import MapLayer from '../map/map-layer-manager/map-layer.enum';
import setVesselFeatures from '../map/map-layer-manager/vessel-utils/set-vessel-features';
import MapHelpers from '../map/map.utils';
import { HistoricVesselPoint } from '../maritime-menu-options/history-panel/historic-vessel-point.model';
import AISDataGapsController from '../maritime-menu-options/history-panel/history-ais-data-gaps/ais-data-gaps-controller.utils';
import AISMergedPointsController from '../maritime-menu-options/history-panel/history-ais-merged-points/history-ais-merged-points-controller.utils';
import { HistoryFormValues } from '../maritime-menu-options/history-panel/history-form/history-form-validators';
import VesselHistoryController from '../maritime-menu-options/history-panel/vessel-history-controller.utils';
import RfEventController from '../maritime-menu-options/rf-data-panel/rf-event-controller.utils';
import RfTargetController from '../maritime-menu-options/rf-data-panel/rf-target-controller.utils';
import { RfEvent, RfTarget } from '../models/rf-data/rf-data.model';
import {
  MaritimeAisApiLocationData,
  VesselData,
} from '../models/vessels/maritime-ais-api';
import { Vessel, VesselSource } from '../models/vessels/vessel.model';
import {
  clearVesselHistoryData,
  prependHistoricVesselPoints,
  setError,
  setHistoricVesselPoints,
  setLoading,
  setMergedHistoricVesselPoints,
  setVesselHistoryData,
} from '../state/history/history.slice';
import {
  RfDataPanelViewState,
  setRfData,
  setRfTargets,
  setViewState as setRfViewState,
} from '../state/rf-data/rf-data.slice';
import store from '../store';
import TenantId from '../tenant';
import { UserPreferences } from '../user-settings/user-preferences/user-preferences.slice';
import { IMO_LENGTH, MMSI_LENGTH } from './vessel-constants.utils';

export const buildIdentifiers = (
  input: string[]
): {
  imos: string[];
  mmsis: string[];
} => {
  const seperated = input.reduce(
    (acc, str) => {
      if (str.length === IMO_LENGTH) {
        acc.imos.push(str);
      } else if (str.length === MMSI_LENGTH) {
        acc.mmsis.push(str);
      }
      return acc;
    },
    { imos: [], mmsis: [], callsign: [], shiptype: [] } as {
      imos: string[];
      mmsis: string[];
      callsign: string[];
      shiptype: string[];
    }
  );
  return seperated;
};

export const maritimeAisResponseToVessels = (
  maritimeAis: MaritimeAisApiLocationData
) => {
  const vessels: Vessel[] = [];
  Object.values(maritimeAis.data).forEach((vesselData) => {
    if (vesselData.vessel) {
      vessels.push({
        vessel_id: vesselData.vessel.vesselId,
        name: vesselData.vessel.staticData.name,
        imo: vesselData.vessel.staticData.imo,
        mmsi: vesselData.vessel.staticData.mmsi,
        heading: vesselData.messages[0].heading,
        latitude: vesselData.messages[0].position?.coordinates[1] || 0,
        longitude: vesselData.messages[0].position?.coordinates[0] || 0,
        course: vesselData.messages[0].course,
        callsign: vesselData.vessel.staticData.callsign,
        shiptype: vesselData.vessel.staticData.shipType,
        timestamp: vesselData.messages[0].timestamp,
        speed: vesselData.messages[0].speed,
        source: VesselSource.AIS,
      });
    }
  });
  return vessels;
};

export const renderNearbyVessels = (
  maritimeAisResponse: MaritimeAisApiLocationData | null
) => {
  if (!maritimeAisResponse) {
    setVesselFeatures(MapLayer.NEARBY_VESSELS, []);
  } else {
    const vessels = maritimeAisResponseToVessels(maritimeAisResponse);
    setVesselFeatures(MapLayer.NEARBY_VESSELS, vessels);
  }
  MapHelpers.setLayerVisibilityIfExists(MapLayer.NEARBY_VESSELS, true);
  if (MapHelpers.getLayer(MapLayer.MY_FLEET_SELECTED_AIS_POSITION)) {
    MapHelpers.moveLayer(
      MapLayer.NEARBY_VESSELS,
      MapLayer.MY_FLEET_SELECTED_AIS_POSITION
    );
  } else {
    MapHelpers.moveLayer(MapLayer.NEARBY_VESSELS);
  }
};

export const renderHistoricVessels = (
  maritimeAisResponse: MaritimeAisApiLocationData | null
) => {
  if (!maritimeAisResponse) {
    setVesselFeatures(MapLayer.HISTORIC_VESSELS, []);
  } else {
    const vessels = maritimeAisResponseToVessels(maritimeAisResponse);
    setVesselFeatures(MapLayer.HISTORIC_VESSELS, vessels);
  }
  MapHelpers.setLayerVisibilityIfExists(MapLayer.HISTORIC_VESSELS, true);
  if (MapHelpers.getLayer(MapLayer.MY_FLEET_SELECTED_AIS_POSITION)) {
    MapHelpers.moveLayer(
      MapLayer.HISTORIC_VESSELS,
      MapLayer.MY_FLEET_SELECTED_AIS_POSITION
    );
  } else {
    MapHelpers.moveLayer(MapLayer.HISTORIC_VESSELS);
  }
};

export function renderVesselHistory(
  rawVesselPoints: HistoricVesselPoint[],
  userPreferences: UserPreferences,
  { prepend, shouldDisplayOtherVessels } = {
    prepend: true,
    shouldDisplayOtherVessels: false,
  }
) {
  const mergedFeaturePoints =
    AISMergedPointsController.getMergedFeaturePoints(rawVesselPoints);

  const formattedVesselPoints =
    AISMergedPointsController.addMergedFeaturePointData(
      rawVesselPoints,
      mergedFeaturePoints
    );

  MapHelpers.setLayerVisibilityIfExists(
    MapLayer.MY_FLEET_VESSELS,
    shouldDisplayOtherVessels
  );
  MapHelpers.setLayerVisibilityIfExists(
    MapLayer.CURRENTLY_ALERTING_VESSELS,
    shouldDisplayOtherVessels
  );
  MapHelpers.setLayerVisibilityIfExists(
    MapLayer.CURRENTLY_ALERTING_VESSELS_POLYGON,
    shouldDisplayOtherVessels
  );
  MapHelpers.setLayerVisibilityIfExists(
    MapLayer.VESSEL_FOCUS_RING,
    shouldDisplayOtherVessels
  );
  MapHelpers.setLayerVisibilityIfExists(
    MapLayer.CURRENTLY_ALERTING_ROUTES,
    shouldDisplayOtherVessels
  );
  MapHelpers.setLayerVisibilityIfExists(
    MapLayer.CURRENTLY_ALERTING_ROUTES_RADIUS,
    shouldDisplayOtherVessels
  );

  store.dispatch(setError(false));
  if (prepend) {
    store.dispatch(prependHistoricVesselPoints(formattedVesselPoints));
  } else {
    store.dispatch(setHistoricVesselPoints(formattedVesselPoints));
  }
  store.dispatch(setMergedHistoricVesselPoints(mergedFeaturePoints));

  VesselHistoryController.init(formattedVesselPoints, userPreferences);

  AISDataGapsController.resetToDefaults();
  AISMergedPointsController.resetToDefaults();
}

export const getRawVesselPoints = (data: any[]) =>
  data.flatMap(
    (vessel: { messages: HistoricVesselPoint[]; vessel: Vessel }) => {
      const rawVessels: HistoricVesselPoint[] = [];
      if (vessel.messages.length > 0 && vessel.vessel) {
        vessel.messages.forEach((message) => {
          const newMessage: HistoricVesselPoint = {
            ...message,
            imo: vessel.vessel.imo,
            name: vessel.vessel.name,
            callsign: vessel.messages[0].callsign,
            shiptype: vessel.messages[0].shiptype,
          };
          rawVessels.push(newMessage);
        });
      }
      return rawVessels;
    }
  );

export const getVesselHistoryData = (
  values: HistoryFormValues,
  tenantId?: TenantId,
  userPreferences?: UserPreferences
) => {
  const identifiers = buildIdentifiers(values.identifiers.split(' '));

  const queryParams = {
    'start-date': values.fromDate,
    'end-date': values.toDate,
    'sample-rate': values.sampleRate,
    mmsis: identifiers.mmsis,
    imos: identifiers.imos,
    tenantId,
  };
  return getVesselHistory(queryParams)
    .then((response: VesselHistoryResponse | VesselData[]) => {
      // response should only be null if we were in websocket mode, which we are not
      const { data } = response as VesselHistoryResponse;
      store.dispatch(
        setVesselHistoryData({
          data,
          formValues: values,
        })
      );

      const rawVesselPoints = getRawVesselPoints(data);
      renderVesselHistory(rawVesselPoints, userPreferences!);
      VesselHistoryController.onVesselHistoryDraw();
    })
    .catch(() => {
      store.dispatch(setError(true));
    })
    .finally(() => {
      store.dispatch(setLoading(false));
    });
};

export const clearVesselHistory = () => {
  store.dispatch(clearVesselHistoryData());
  VesselHistoryController.clearAllHistoryLayers();
};

export const clearNearbyVessels = () => {
  setVesselFeatures(MapLayer.NEARBY_VESSELS, []);
  MapHelpers.setLayerVisibilityIfExists(MapLayer.NEARBY_VESSELS, true);
};

export const clearHistoricVessels = () => {
  setVesselFeatures(MapLayer.HISTORIC_VESSELS, []);
  MapHelpers.setLayerVisibilityIfExists(MapLayer.HISTORIC_VESSELS, true);
};

export const hasMatchingMMSI = (
  vesselsArray: any,
  currentVessel: any
): boolean =>
  vesselsArray.some(
    (vessel: any) =>
      vessel.mmsi === currentVessel.mmsi &&
      vessel !== currentVessel &&
      vessel.mmsi
  );

export function renderVesselRfData(rfFeatures: RfEvent[]) {
  store.dispatch(setRfData(rfFeatures));
  // by default the rf data is hidden, so do not do any map movements here
  RfEventController.init();
}

export function renderRfTargets(rfTargets: RfTarget[]) {
  store.dispatch(setRfTargets(rfTargets));
  RfTargetController.init();
  store.dispatch(setRfViewState(RfDataPanelViewState.TARGETS));
}
