/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type VesselSourceFilter = {
  ais: boolean;
  riskIntelligence: boolean;
};

export type VesselListsFilter = {
  name: string;
  imo: string;
  mmsi: string;
  callsign: string;
  shipType: string;
};

export type FleetFilters = {
  vesselSource: VesselSourceFilter;
  search: string;
  open: boolean;
  vesselListsVessels: VesselListsFilter[];
};

export const DEFAULT_FLEET_FILTERS: FleetFilters = {
  vesselSource: {
    ais: false,
    riskIntelligence: false,
  },
  search: '',
  open: false,
  vesselListsVessels: [],
};

interface IMyFleetPanel {
  filters: FleetFilters;
}

export const INITIAL_MY_FLEET_PANEL_STATE: IMyFleetPanel = {
  filters: DEFAULT_FLEET_FILTERS,
};

const MyFleetPanelSlice = createSlice({
  name: 'myFleetFilter',
  initialState: INITIAL_MY_FLEET_PANEL_STATE,
  reducers: {
    setFleetFilters: (
      state,
      action: PayloadAction<Partial<FleetFilters> | null>
    ) => {
      // Only updates filters explicitly mentioned in action
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function

export const { setFleetFilters } = MyFleetPanelSlice.actions;

export default MyFleetPanelSlice.reducer;
