import TenantId from '../../tenant';

export enum EAlertTypes {
  AIS_OFF = 'AIS_OFF',
  AIS_ON = 'AIS_ON',
  ENTER_ROI = 'ENTER_ROI',
  EXIT_ROI = 'EXIT_ROI',
  STATIC_CHANGE = 'STATIC_CHANGE',
  STS = 'STS',
  INCIDENTS = 'INCIDENTS',
  INCIDENTS_ROUTE = 'INCIDENTS_ROUTE',
  ENTER_PORT = 'ENTER_PORT',
  DEP_PORT = 'DEP_PORT',
}

export const DEFAULT_ALERTS = [
  EAlertTypes.AIS_OFF,
  EAlertTypes.AIS_ON,
  EAlertTypes.DEP_PORT,
  EAlertTypes.ENTER_PORT,
  EAlertTypes.ENTER_ROI,
  EAlertTypes.EXIT_ROI,
  EAlertTypes.INCIDENTS_ROUTE,
  EAlertTypes.STATIC_CHANGE,
  EAlertTypes.STS,
];

export const AlertTypesByTenant: {
  [key in TenantId]: EAlertTypes[];
} = {
  [TenantId.RISK_INTELLIGENCE]: [
    EAlertTypes.INCIDENTS,
    EAlertTypes.INCIDENTS_ROUTE,
    EAlertTypes.ENTER_ROI,
  ],
  [TenantId.GEOLLECT]: DEFAULT_ALERTS,
};

export interface AlertNotificationPreferences {
  alert_config_id: string;
  company_id: string;
  region_id: string | null;
  alert_modifiers: Record<string, any> | null;
  alert_type: EAlertTypes;
  ports: AlertPortModifiers | null;
  email_frequency: number | string | null;
  notification_last_sent_time: Date | string | null;
  name: string | null;
  description: string | null;
}

export interface SendingAlertNotificationPreferences {
  alert_config_id: string;
  email_frequency: number | string | null;
  notification_last_sent_time: string | null;
}

export interface AlertPortModifiers {
  filter_type: string;
  country_codes?: string[] | null;
  locodes?: string[] | null;
}

export interface AlertConfiguration {
  alert_config_id: string | null;
  alert_type: EAlertTypes;
  company_id: string;
  alert_modifiers: Record<string, any> | null;
  boundary_id: string | null;
  region_id: string | null;
  ports: AlertPortModifiers | null;
  name: string;
  description: string;
  email_from_address: string;
}

export interface DeleteAlertConfiguration {
  alert_config_id: string;
  company_id: string;
}

export enum GeometryType {
  RI_AREA = 'RI Maritime Area',
  BOUNDARY = 'Boundary',
  DRAWING = 'Drawing',
  GLOBAL = 'Global',
}
