import { BBox, WFSResponse } from '../models/geoserver/geoserver.model';
import { wrapRequest } from './base';

export const GLOBAL_AIS_DRAW_LIMIT = 10000;
export const GEOSERVER_WORKSPACE = 'riMaritimeWS';
export const GEOSERVER_LAYER = 'vessels_lkl';

export const requestParameters = (bbox: BBox, count: number) => ({
  outputFormat: 'application/json',
  service: 'wfs',
  version: '2.0.0',
  request: 'GetFeature',
  typeNames: `${GEOSERVER_WORKSPACE}:${GEOSERVER_LAYER}`,
  count,
  srsName: 'EPSG:4326',
  bbox: `${bbox.join(',')},EPSG:4326`,
  sortBy: 'timestamp +D',
});

const requestWFS = async (
  bbox: BBox,
  count: number,
  token: string
): Promise<WFSResponse> => {
  const params = requestParameters(bbox, count);

  const response = await wrapRequest('get', 'atlas', '/ogc/wfs', {
    queryStringParameters: { ...params },
    headers: { Authorization: token },
  });

  return response;
};

export default requestWFS;
