import { Company } from '../../admin/admin-page/admin.slice';
import { kebabify } from '../../utils/text-formatting.utils';
import { wrapRequest } from '../base';

export const getCompany = async (companyIdOrName: string): Promise<Company> => {
  const result = await wrapRequest<Company[]>('get', 'geonius', `/companies`);
  if (result.length === 0) {
    throw new Error('Company not found');
  }
  const normalisedCompanyName = kebabify(companyIdOrName);
  // can search by company name or id
  const company = result.find(
    (c) =>
      c.company_id === companyIdOrName ||
      kebabify(c.company_name) === normalisedCompanyName
  );
  if (!company) {
    throw new Error('Company not found');
  }
  return company;
};

export const getCompanies = async (): Promise<Company[]> =>
  wrapRequest('get', 'geonius', `/companies`);

export const postCompany = async (
  companyName: string,
  tenantId: string
): Promise<'Successfully created Company'> =>
  wrapRequest('post', 'geonius', `/companies`, {
    body: {
      data: {
        company_name: companyName,
        tenant_id: tenantId,
      },
    },
  });

export const deleteCompanies = async (
  companyIds: string[]
): Promise<'Successfully deleted company or companies'> =>
  wrapRequest('del', 'geonius', `/companies`, {
    body: {
      data: {
        company_ids: companyIds,
      },
    },
  });
