import {
  Privileges,
  calculatePrivileges,
} from '../../hooks/access-control/privileges';
import {
  PortsFilters,
  setPortsFilters,
} from '../../maritime-menu-options/world-ports-panel/world-ports-panel.slice';
import {
  ExpandedRIPort,
  Port,
  RIPort,
} from '../../maritime-menu-options/world-ports-panel/world-ports.model';
import { setError, setPorts } from '../../state/ports/ports.slice';
import store from '../../store';
import { Token } from '../../user/user.slice';
import DownloadHelpers from '../../utils/download.utils';
import { getCompanyPermissionToggles } from '../../utils/permission-toggles.utils';
import { getRICountryTitle } from '../../utils/risk-intelligence-helpers';
import { wrapRequest } from '../base';

// if a and b's COUNTRY is the same, localeCompare will return 0, so move on to localeComparing Port Name.
export const portSortFunction = (a: Port, b: Port) =>
  a.COUNTRY.localeCompare(b.COUNTRY) || a.NAME.localeCompare(b.NAME);
/**
 * To avoid O(n^2) time complexity, we will use a Map to store the RI ports
 * then traverse the WPI ports list and check if the RI ports Map contains that port
 * @param wpiPorts
 * @param riPorts
 */

const mergePorts = (
  wpiPorts: Port[],
  riPorts: RIPort[],
  threatLevel?: string[]
) => {
  const riPortsMap = new Map<string, RIPort[]>();

  // Step 1: Create a mapping of riPorts with the same WPI into an array
  riPorts.forEach((riPort) => {
    if (riPort.wpi) {
      const wpiKey = riPort.wpi.toString();
      if (!riPortsMap.has(wpiKey)) {
        riPortsMap.set(wpiKey, []);
      }
      riPortsMap.get(wpiKey)!.push(riPort);
    }
  });

  // Step 2: Merge wpiPorts with the same index with each riPort
  const ports = wpiPorts.map((wpiPort) => {
    const wpiKey = wpiPort.WPI;
    const riPortArray = riPortsMap.get(wpiKey) || [];

    // If there are multiple riPorts with the same WPI, merge each one
    const mergedRiPorts = riPortArray.map((riPort) => {
      // Remove the matched riPort from the map
      riPortsMap.delete(wpiKey);

      return {
        ...wpiPort,
        ...(riPort.title && { NAME: riPort.title }), // Where it exists, use the RI port title
        ri: riPort,
      };
    });

    // If there are no riPorts with the same WPI, return the original wpiPort (unless RI filters are in play)
    if (mergedRiPorts.length) {
      return mergedRiPorts;
    }
    if (threatLevel?.length) {
      // WPI-only ports must not match RI filters
      return [];
    }
    return wpiPort;
  });

  // Step 3: Iterate through remaining riPorts in the Map.
  riPortsMap.forEach((riPortArray) => {
    // Add a new object to the ports array for each remaining riPort.
    riPortArray.forEach((riPort) => {
      ports.push({
        NAME: riPort.title,
        UNLOCODE: riPort.unlocode,
        WPI: riPort.wpi.toString(),
        LAT: riPort.position.latitude.toString(),
        LONG: riPort.position.longitude.toString(),
        COUNTRY: getRICountryTitle(riPort.country),
        ri: riPort,
      });
    });
  });

  return ports.flat(); // Flatten the array if there are multiple entries for a single wpiPort
};

export const getRiskIntelligencePort = async (
  portId: string | number
): Promise<ExpandedRIPort> =>
  wrapRequest('get', 'geonius', `/risk-intelligence/ports/${portId}`);

export const getRiskIntelligencePorts = async (
  threatLevel?: any[]
): Promise<RIPort[]> => {
  const threatParams =
    (threatLevel &&
      threatLevel?.length > 0 &&
      `?threat-level=${threatLevel?.join(',')}`) ||
    '';
  return wrapRequest(
    'get',
    'geonius',
    `/risk-intelligence/ports${threatParams}`
  );
};

export const getPortThreatTypes = async (): Promise<RIPort[]> =>
  wrapRequest('get', 'geonius', `/risk-intelligence/threat-types`);

export const getWPIPorts = async (): Promise<Port[]> => {
  const data = await wrapRequest('get', 'geonius', `/wpi/ports`);
  const wpiPorts = DownloadHelpers.processGzippedJson<Port[]>(data);
  return wpiPorts;
};

const filterRiPortsByPrivileges = (
  riPorts: RIPort[],
  privileges: Privileges
) => {
  const { canAccessPorts } = privileges;

  const cruisePortFilter = (riPort: RIPort) =>
    riPort.cruise && canAccessPorts.cruise;
  const inlandPortFilter = (riPort: RIPort) =>
    riPort.inland && canAccessPorts.land;
  const coastalPortFilter = (riPort: RIPort) =>
    !riPort.inland && !riPort.cruise && canAccessPorts.coastal;

  const filteredRiPorts = riPorts.filter(
    (riPort) =>
      cruisePortFilter(riPort) ||
      inlandPortFilter(riPort) ||
      coastalPortFilter(riPort)
  );

  return filteredRiPorts;
};

export const getMergedPorts = async (
  idToken: Token | null,
  threatLevel?: string[]
) => {
  const licenses = store.getState().user.featureFlags ?? null;
  const companyPermissionToggles = getCompanyPermissionToggles();

  const privileges = calculatePrivileges({
    idToken,
    userFeatureFlags: licenses,
    companyPermissionToggles,
  });

  const wpiPorts = privileges.canAccessPorts.wpi ? await getWPIPorts() : [];
  if (!idToken?.tenantId) {
    return wpiPorts.sort(portSortFunction);
  }

  const riPorts = await getRiskIntelligencePorts(threatLevel);
  const filteredRiPorts = filterRiPortsByPrivileges(riPorts, privileges);

  return mergePorts(wpiPorts, filteredRiPorts, threatLevel).sort(
    portSortFunction
  );
};

export const extractPortThreatTypesFromFilter = (filters: PortsFilters) =>
  filters.threatType
    .filter((type) => type.value !== 0)
    .map((t) => `${t.id}<=${t.value}`);

export const fetchPortThreats = async () => {
  try {
    getPortThreatTypes().then((data) => {
      const threatTypes = data.map((d) => ({
        id: d.id,
        title: d.title,
        value: 0,
      }));
      store.dispatch(
        setPortsFilters({
          threatType: threatTypes,
        })
      );
    });
  } catch (e) {
    store.dispatch(setError());
  }
};

export const getPorts = async (
  idToken: Token | null,
  threatLevel?: string[]
): Promise<Port[]> => {
  try {
    const mergedPorts = await getMergedPorts(idToken, threatLevel);
    store.dispatch(setPorts(mergedPorts));
    return mergedPorts;
  } catch (e) {
    store.dispatch(setError());
  }
  return [];
};

export const applyPortFilter = (ports: Port[], filters: PortsFilters): Port[] =>
  ports.filter((port) => {
    const { inland, cruise, coastal, wpi } = filters.portType;

    const matchByText = () =>
      port.NAME.toLocaleLowerCase().includes(
        filters.search.toLocaleLowerCase()
      ) ||
      port.COUNTRY.toLocaleLowerCase().includes(
        filters.search.toLocaleLowerCase()
      );

    const matchByFilters = () => {
      if (inland || cruise || wpi || coastal) {
        return (
          (inland && port.ri?.inland) ||
          (cruise && port.ri?.cruise) ||
          (coastal && port.ri?.cruise === false) ||
          (wpi && port.WPI)
        );
      }
      return true;
    };

    const matchByRisk = () => {
      const riskFilters = Object.keys(filters.riskLevel)
        .filter((key) => filters.riskLevel[key])
        .map((key) => `PAL ${key.split(' ')[1]}`);
      if (riskFilters.length > 0) {
        return port.ri && port.ri.pal && riskFilters.includes(port.ri.pal);
      }
      return true;
    };

    return matchByText() && matchByRisk() && matchByFilters();
  });
