import defaultTheme from './default-theme';

const geollectTheme = {
  ...defaultTheme,
  palette: {
    mode: 'dark',
    primary: {
      main: '#2abbaf',
      icon: '#2abbaf',
      dark: '#49454f',
      light: '#71797e',
      border: '#cac4d0',
      hover: '#49454f',
    },
    secondary: {
      icon: '#71797e',
      main: '#5b6771',
      dark: '#19222a',
      light: '#71797e',
      border: '#79747e',
    },
    background: {
      default: '#383334',
      paper: '#231f20',
      menu: '#383334',
      light: '#696969', // I'm not memeing, this is the actual css color 'dim-grey'
      menuBorder: '#231f20',
    },
    text: {
      primary: '#fff',
      secondary: '#bbb',
      light: '#fff',
    },
    white: '#fff',
    black: '#000',
  },
};

export default geollectTheme;
