import dayjs from 'dayjs';
import { FormikValues } from 'formik';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export interface HistoricVesselsFormValues extends FormikValues {
  radius: number;
  centrePoint: {
    latitude: string;
    longitude: string;
  };
  dateTimeRange: {
    startDate: string;
    endDate: string;
  };
}

const currentDate = new Date();
currentDate.setDate(currentDate.getDate() - 1);
const startDate = currentDate.toISOString();
const endDate = dayjs().toISOString();

export const defaultValues: HistoricVesselsFormValues = {
  radius: 50,
  centrePoint: { latitude: '00° 00\' 00" N', longitude: '00° 00\' 00" E' },
  dateTimeRange: {
    startDate,
    endDate,
  },
};
