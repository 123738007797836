/* eslint-disable no-param-reassign */
import { Explore, SvgIconComponent, TravelExplore } from '@mui/icons-material';
import AltRoute from '@mui/icons-material/AltRoute';
import Anchor from '@mui/icons-material/Anchor';
import CreateOutlined from '@mui/icons-material/CreateOutlined';
import DescriptionOutlined from '@mui/icons-material/DescriptionOutlined';
import DirectionsBoatOutlined from '@mui/icons-material/DirectionsBoatOutlined';
import DirectionsBoatSharp from '@mui/icons-material/DirectionsBoatSharp';
import FeedbackOutlined from '@mui/icons-material/FeedbackOutlined';
import FmdGoodOutlined from '@mui/icons-material/FmdGoodOutlined';
import Fullscreen from '@mui/icons-material/Fullscreen';
import GroupOutlined from '@mui/icons-material/GroupOutlined';
import Handyman from '@mui/icons-material/Handyman';
import HelpOutlineOutlined from '@mui/icons-material/HelpOutlineOutlined';
import History from '@mui/icons-material/History';
import LockOutlined from '@mui/icons-material/LockOutlined';
import MapOutlined from '@mui/icons-material/MapOutlined';
import NavigationIcon from '@mui/icons-material/Navigation';
import NotificationsOutlined from '@mui/icons-material/NotificationsOutlined';
import Satellite from '@mui/icons-material/Satellite';
import SatelliteAltOutlined from '@mui/icons-material/SatelliteAltOutlined';
import SaveOutlined from '@mui/icons-material/SaveOutlined';
import SensorsIcon from '@mui/icons-material/Sensors';
import TapAndPlay from '@mui/icons-material/TapAndPlay';
import WaterIcon from '@mui/icons-material/Water';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export enum EMenuItems {
  NOTHING_SELECTED = '',
  CITIES = 'Cities',
  COUNTRIES = 'Countries',
  MAPS = 'Maps / Charts',
  CAPELLA = 'SAR Imagery',
  FLEET = 'Fleet',
  FLEETS = 'Fleets',
  ALERTS = 'Notifications',
  INCIDENTS = 'Incidents',
  BOUNDARIES = 'Boundaries',
  PORTS = 'Ports',
  ROUTES = 'Routes',
  DRAWINGS = 'Drawings',
  RI_MARITIME_AREAS = 'Risk Intelligence Maritime Areas',
  HISTORY = 'History',
  DOCUMENTS = 'Documents',
  SAVE_SETUP = 'Save Setup',
  DATA_POLICY = 'Data Policy',
  FEEDBACK = 'Feedback & Support',
  HELP_PAGES = 'Help Pages',
  TOOLS = 'Tools',
  RF_DATA = 'Position Validation',
  UNSEEN = 'Radio Frequency Data',
  ADTECH = 'Adtech Data',
  NEARBY_VESSELS = 'Nearby Vessels',
  SUBSEA_INFRASTRUCTURE = 'Subsea Infrastructure',
  AVCS = 'AVCS Online',
  ESA = 'ESA Sentinel 2',
  GLOBAL_AIS = 'Global AIS',
}

export enum EDossiers {
  NOTHING = '',
  CITY = 'City',
  COUNTRY = 'Country',
  INCIDENT = 'Incident',
  HISTORY = 'History',
  PORT = 'Port',
  DRAWING = 'Drawing',
  RI_MARITIME_AREA = 'RI Maritime Area',
  ROUTE = 'Route',
  VESSEL = 'Vessel',
  ADTECH = 'Adtech',
  NEARBY_VESSELS = 'Nearby Vessels',
  SUBSEA_INFRASTRUCTURE = 'Subsea Infrastructure',
  AVCS = 'AVCS Online',
  ESA = 'ESA Sentinel 2',
}

export enum EMenuIcons {
  MAPS = 'MapOutlined',
  CAPELLA = 'SatelliteAltOutlined',
  FLEET = 'DirectionsBoatOutlined',
  FLEETS = 'DirectionsBoatSharp',
  ALERTS = 'NotificationsOutlined',
  INCIDENTS = 'FmdGoodOutlined',
  BOUNDARIES = 'Fullscreen',
  PORTS = 'Anchor',
  ROUTES = 'AltRoute',
  DRAWINGS = 'CreateOutlined',
  RI_MARITIME_AREAS = 'GroupOutlined',
  HISTORY = 'History',
  DOCUMENTS = 'DescriptionOutlined',
  SAVE_SETUP = 'SaveOutlined',
  DATA_POLICY = 'LockOutlined',
  FEEDBACK = 'FeedbackOutlined',
  HELP_PAGES = 'HelpOutlineOutlined',
  TOOLS = 'Handyman',
  UNSEEN = 'Satellite',
  RF_DATA = 'SensorsIcon',
  ADTECH = 'TapAndPlay',
  SUBSEA_INFRASTRUCTURE = 'WaterIcon',
  AVCS = 'Explore',
  ESA = 'TravelExplore',
  GLOBAL_AIS = 'NavigationIcon',
}

export const MenuIcons: Record<EMenuIcons, SvgIconComponent> = {
  MapOutlined,
  DirectionsBoatOutlined,
  DirectionsBoatSharp,
  NotificationsOutlined,
  FmdGoodOutlined,
  Fullscreen,
  Anchor,
  AltRoute,
  CreateOutlined,
  GroupOutlined,
  History,
  SaveOutlined,
  LockOutlined,
  FeedbackOutlined,
  HelpOutlineOutlined,
  DescriptionOutlined,
  Handyman,
  SatelliteAltOutlined,
  Satellite,
  SensorsIcon,
  TapAndPlay,
  WaterIcon,
  Explore,
  TravelExplore,
  NavigationIcon,
};

export const EMenuTitles = {
  ALERTS_AND_INCIDENTS: 'Notifications & Incidents',
  MY_FLEET: 'My Fleet',
  AREAS: 'Areas',
  OBSERVATIONS: 'Observations',
  MAP_AND_TOOLS: 'Maps & Tools',
  USER: 'User',
};

export interface MenuState {
  menuOpen: boolean;
  selectedOption: EMenuItems;
  secondaryMenuOpen: boolean;
  selectedDossier: EDossiers;
  dossierHistory: { dossier: EDossiers; option: EMenuItems }[];
  securityRiskAssessmentOpen: boolean;
  threatAssessmentOpen: boolean;
  vulnerabilityAssessmentOpen: boolean;
  selectedAreaDocumentId: string;
  timelineMinimised: boolean;
}

export const INITIAL_MENU_STATE: MenuState = {
  menuOpen: false,
  selectedOption: EMenuItems.NOTHING_SELECTED,
  dossierHistory: [],
  secondaryMenuOpen: false,
  selectedDossier: EDossiers.NOTHING,
  securityRiskAssessmentOpen: false,
  threatAssessmentOpen: false,
  vulnerabilityAssessmentOpen: false,
  selectedAreaDocumentId: '',
  timelineMinimised: false,
};

const MenuSlice = createSlice({
  name: 'menu',
  initialState: INITIAL_MENU_STATE,
  reducers: {
    toggleMainMenu: (state) => {
      state.menuOpen = !state.menuOpen;
    },
    closeMainMenu: (state) => {
      state.menuOpen = false;
    },
    closeMenus: (state) => {
      state.menuOpen = false;
      state.secondaryMenuOpen = false;
      state.selectedOption = EMenuItems.NOTHING_SELECTED;
    },
    toggleSecondaryMenu: (state, action) => {
      if (action.payload) {
        state.secondaryMenuOpen = action.payload;
        state.menuOpen = false;
      } else {
        state.secondaryMenuOpen = action.payload;
      }
    },
    setSecondaryMenu: (state) => {
      state.secondaryMenuOpen = !state.secondaryMenuOpen;
    },
    setAllDossierClosed: (state) => {
      state.selectedDossier = EDossiers.NOTHING;
      state.dossierHistory = [];
      state.threatAssessmentOpen = false;
      state.securityRiskAssessmentOpen = false;
      state.vulnerabilityAssessmentOpen = false;
    },
    clearDossierHistory: (state) => {
      state.dossierHistory = [];
    },
    setAllAssessmentsClosed: (state) => {
      state.threatAssessmentOpen = false;
      state.securityRiskAssessmentOpen = false;
      state.vulnerabilityAssessmentOpen = false;
    },
    toggleVulnerabilityAssessment: (state) => {
      state.vulnerabilityAssessmentOpen = !state.vulnerabilityAssessmentOpen;
    },
    toggleSecurityRiskAssessment: (state) => {
      state.securityRiskAssessmentOpen = !state.securityRiskAssessmentOpen;
    },
    setSelectedOption: (state, action: PayloadAction<EMenuItems>) => {
      MenuSlice.caseReducers.setAllAssessmentsClosed(state);
      state.secondaryMenuOpen = true;
      state.selectedOption = action.payload;
    },
    setSelectedDossier: (state, action: PayloadAction<EDossiers>) => {
      state.dossierHistory = [
        ...state.dossierHistory,
        { dossier: state.selectedDossier, option: state.selectedOption },
      ];
      state.selectedDossier = action.payload;
    },
    setSelectedAreaDocument: (state, action: PayloadAction<string>) => {
      state.selectedAreaDocumentId = action.payload;
    },
    setThreatAssessmentOpen: (state, action: PayloadAction<boolean>) => {
      MenuSlice.caseReducers.setAllDossierClosed(state);
      state.threatAssessmentOpen = action.payload;
      state.selectedOption = EMenuItems.NOTHING_SELECTED;
    },
    setSecurityRiskAssessmentOpen: (state, action: PayloadAction<boolean>) => {
      MenuSlice.caseReducers.setAllDossierClosed(state);
      state.securityRiskAssessmentOpen = action.payload;
      state.selectedOption = EMenuItems.NOTHING_SELECTED;
    },
    setVulnerabilityAssessmentOpen: (state, action: PayloadAction<boolean>) => {
      MenuSlice.caseReducers.setAllDossierClosed(state);
      state.vulnerabilityAssessmentOpen = action.payload;
      state.selectedOption = EMenuItems.NOTHING_SELECTED;
    },
    setTimelineMinimised: (state, action: PayloadAction<boolean>) => {
      state.timelineMinimised = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function

export const {
  toggleMainMenu,
  closeMenus,
  closeMainMenu,
  toggleSecondaryMenu,
  setSecondaryMenu,
  setThreatAssessmentOpen,
  setSecurityRiskAssessmentOpen,
  setVulnerabilityAssessmentOpen,
  setSelectedOption,
  setSelectedDossier,
  setAllDossierClosed,
  setAllAssessmentsClosed,
  setSelectedAreaDocument,
  setTimelineMinimised,
} = MenuSlice.actions;

export default MenuSlice.reducer;
