import requestWFS, { GEOSERVER_LAYER } from '../../../api/geoserver';
import { useAppDispatch } from '../../../hooks';
import { BBox, RawWFSFeature } from '../../../models/geoserver/geoserver.model';
import {
  clearFilteredData,
  clearRawData,
  setLoading,
  setLoadingOff,
  updateRawData,
} from '../../../state/global-ais/global-ais.slice';
import store from '../../../store';
import { refreshOAuthToken } from '../../../utils/auth-helpers.utils';
import MapHelpers from '../../map.utils';
import MapLayer from '../map-layer.enum';
import {
  cleanShipTypeAndFlag,
  parseMapBounds,
  splitBbox,
} from './global-ais-map-move.utils';

export const conditionalMyFleetFilter = (
  newData: RawWFSFeature[]
): RawWFSFeature[] => {
  const map = MapHelpers.getMapInstance();
  const myFleetVessels = store.getState().myFleet.myFleet?.allIds ?? [];
  if (
    map.getLayoutProperty(MapLayer.MY_FLEET_VESSELS, 'visibility') === 'none' ||
    !myFleetVessels.length
  ) {
    return newData;
  }

  const renamedMyFleetVessels = myFleetVessels.map(
    (vessel) => `${GEOSERVER_LAYER}.${vessel}`
  );
  return newData.filter((vessel) => !renamedMyFleetVessels.includes(vessel.id));
};

export async function fetchAndSetData(
  bbox: BBox,
  dispatch: ReturnType<typeof useAppDispatch>,
  token: string
) {
  try {
    const response = await requestWFS(bbox, 7500, token);
    const newData = conditionalMyFleetFilter(response.features);
    const cleanData = cleanShipTypeAndFlag(newData);
    dispatch(updateRawData(cleanData));
  } catch (error) {
    /* empty */
  }
}

export async function refreshWFSData(
  dispatch: ReturnType<typeof useAppDispatch>
) {
  try {
    const { loading } = store.getState().globalAis;
    const map = MapHelpers.getMapInstance();
    if (
      loading ||
      !map ||
      map.getLayoutProperty(MapLayer.GLOBAL_AIS_VESSELS, 'visibility') ===
        'none'
    ) {
      return;
    }

    dispatch(clearRawData());
    dispatch(clearFilteredData());
    dispatch(setLoading());

    const authorised = await refreshOAuthToken(dispatch);

    if (!authorised) {
      dispatch(setLoadingOff());
      return;
    }
    const bbox = parseMapBounds(map.getBounds());
    if (!bbox) {
      dispatch(setLoadingOff());
      return;
    }

    const subBboxes = splitBbox(bbox);
    const { daasToken } = store.getState().globalAis;

    await Promise.all(
      subBboxes.map((bbox_) =>
        fetchAndSetData(bbox_, dispatch, daasToken as string)
      )
    );
  } catch {
    /* */
  }
  dispatch(setLoadingOff());
}
