export interface GeoServerResponse {
  data: WFSResponse;
}

export interface WFSResponse {
  bbox: number[];
  crs: {
    properties: {
      name: string;
    };
    type: string;
  };
  features: RawWFSFeature[];
  numberMatched: number;
  numberReturned: number;
  timeStamp: string;
  totalFeatures: number;
  type: string;
}

export interface RawWFSFeature extends GeoJSON.Feature {
  id: string;
  geometry_name: string;
  properties: {
    ship_type: string | null;
    ship_sub_type: string | null;
    heading: number | null;
    course: number | null;
    timestamp: string;
    speed: number | null;
    flag: string | null;
    destination: string;
    name: string;
    mmsi: number;
  };
}

export interface CleanWFSFeature extends RawWFSFeature {
  properties: {
    ship_type: string;
    ship_sub_type: string | null;
    heading: number | null;
    course: number | null;
    timestamp: string;
    speed: number | null;
    flag: string;
    destination: string;
    name: string;
    mmsi: number;
  };
}

// min long, min lat, max long, max lat
export type BBox = [number, number, number, number];

export type ShipType =
  | 'Tanker'
  | 'Pleasure Craft'
  | 'Other'
  | 'Cargo'
  | 'Tugs And Special Craft'
  | 'Passenger'
  | 'Fishing'
  | 'High Speed Craft';

export const SHIP_TYPES: ShipType[] = [
  'Tanker',
  'Pleasure Craft',
  'Other',
  'Cargo',
  'Tugs And Special Craft',
  'Passenger',
  'Fishing',
  'High Speed Craft',
];
