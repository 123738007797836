import { UserPreferences } from '../../../user-settings/user-preferences/user-preferences.slice';
import MapLayerIcon from '../map-layer-icon';
import MapLayerVisibility from '../map-layer-visibility.enum';
import MapLayer from '../map-layer.enum';
import addAnyVesselLayer from './add-any-vessel-layer';

function addHistoricVesselLayerToMap(
  layer: MapLayer | string,
  visibility: MapLayerVisibility = MapLayerVisibility.VISIBLE,
  metadata: object = {},
  userPreferences?: UserPreferences
) {
  const iconColour = '#abfff7';

  addAnyVesselLayer(
    layer,
    metadata,
    visibility,
    MapLayerIcon.PLAIN_VESSEL.NEARBY,
    iconColour,
    userPreferences
  );
}

export default addHistoricVesselLayerToMap;
