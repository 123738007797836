/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { CleanWFSFeature } from '../../models/geoserver/geoserver.model';

type GlobalAisState = {
  loading: boolean;
  rawData: CleanWFSFeature[];
  filteredData: CleanWFSFeature[];
  hideVessels: string[];
  hideFlags: string[];
  drawLimitOverride: boolean;
  daasToken: string | null;
  daasTokenExpiry: number | null;
  error: boolean;
};

export const INITIAL_GLOBAL_AIS_STATE: GlobalAisState = {
  loading: false,
  rawData: [],
  filteredData: [],
  hideVessels: [],
  hideFlags: [],
  drawLimitOverride: false,
  daasToken: null,
  daasTokenExpiry: null,
  error: false,
};

const GlobalAisSlice = createSlice({
  name: 'globalAis',
  initialState: INITIAL_GLOBAL_AIS_STATE,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setLoadingOff: (state) => {
      state.loading = false;
    },
    updateRawData: (state, action) => {
      const newData: CleanWFSFeature[] = action.payload;
      const existingIds = new Set(state.rawData.map((item) => item.id));
      const filteredData = newData.filter((item) => !existingIds.has(item.id));
      state.rawData.push(...filteredData);
    },
    clearRawData: (state) => {
      state.rawData = [];
    },
    setFilteredData: (state, action) => {
      state.filteredData = action.payload;
    },
    clearFilteredData: (state) => {
      state.filteredData = [];
    },
    setHideVessel: (state, action) => {
      const hideVessels: string[] = action.payload;
      hideVessels.forEach((hideVessel) => {
        if (!state.hideVessels.includes(hideVessel)) {
          state.hideVessels.push(hideVessel);
        } else {
          state.hideVessels = state.hideVessels.filter(
            (hideVessel_) => hideVessel_ !== hideVessel
          );
        }
      });
    },
    clearHideVessels: (state) => {
      state.hideVessels = [];
    },
    setHideFlags: (state, action) => {
      const flags: string[] = action.payload;
      flags.forEach((flag) => {
        if (!state.hideFlags.includes(flag)) {
          state.hideFlags.push(flag);
        } else {
          state.hideFlags = state.hideFlags.filter((flag_) => flag_ !== flag);
        }
      });
    },
    clearHideFlags: (state) => {
      state.hideFlags = [];
    },
    setDrawLimitOverride: (state, action) => {
      state.drawLimitOverride = action.payload;
    },
    setDaasToken: (state, action) => {
      state.daasToken = action.payload;
    },
    setDaasTokenExpiry: (state, action) => {
      state.daasTokenExpiry = action.payload;
    },
    setError(state) {
      state.error = true;
    },
    clearError(state) {
      state.error = false;
    },
  },
});

export const {
  setLoading,
  setLoadingOff,
  updateRawData,
  clearRawData,
  setFilteredData,
  clearFilteredData,
  setHideVessel,
  clearHideVessels,
  setHideFlags,
  clearHideFlags,
  setDrawLimitOverride,
  setDaasToken,
  setDaasTokenExpiry,
  setError,
  clearError,
} = GlobalAisSlice.actions;

export default GlobalAisSlice.reducer;
