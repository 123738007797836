import { ThemeOptions } from '@mui/material';

const defaultTheme: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1650, // all default except xl
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          scrollbarWidth: 'thin',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          scrollbarWidth: 'thin',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          borderRadius: '0',
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          background: 'unset',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 9999,
        },
      },
    },
  },

  typography: {
    button: {
      textTransform: 'none',
    },
    h1: {
      fontWeight: 'bold',
    },
    h2: {
      fontWeight: 'bold',
    },
    h3: {
      fontWeight: 'bold',
    },
    h4: {
      fontWeight: 'bold',
    },
    h5: {
      fontWeight: 'bold',
      fontSize: '22px',
    },
    h6: {
      fontWeight: 'bold',
    },
  },
};

export default defaultTheme;
