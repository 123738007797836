import { AnyAction, combineReducers, configureStore } from '@reduxjs/toolkit';
import adminReducer from './admin/admin-page/admin.slice';
import webSocketEventsReducer from './api/web-socket/web-socket.slice';
import timelineReducer from './common-components/timeline/timeline.slice';
import securityRiskAssessmentReducer from './dossiers_and_panels/security-risk-assessments/security-risk-assessment.slice';
import threatAssessmentReducer from './dossiers_and_panels/threat-assessments/threat-assessment.slice';
import vesselDossierReducer from './dossiers_and_panels/vessel-dossier/vessel-dossier.slice';
import vulnerabilityAssessmentReducer from './dossiers_and_panels/vulnerability-assessments/vulnerability-assessment.slice';
import menuReducer from './main-menu/menu.slice';
import mapLayerHandlerReducer from './map-layer-handler/map-layer-handler.slice';
import mapReducer from './map/map.slice';
import adtechPanelReducer from './maritime-menu-options/adtech-data-panel/adtech-panel.slice';
import alertingPanelReducer from './maritime-menu-options/alert-panel/alertingVessels-panel.slice';
import drawingsPanelReducer from './maritime-menu-options/areas-panel/drawings.slice';
import avcsReducer from './maritime-menu-options/avcs-panel/avcs.slice';
import boundariesPanelReducer from './maritime-menu-options/boundaries-panel/boundaries-panel.slice';
import capellaPanelReducer from './maritime-menu-options/capella-panel/capella-panel.slice';
import citiesPanelReducer from './maritime-menu-options/cities-panel/cities-panel.slice';
import countriesPanelReducer from './maritime-menu-options/countries-panel/countries-panel.slice';
import documentsPanelReducer from './maritime-menu-options/documents-panel/documents-panel.slice';
import esaPanelReducer from './maritime-menu-options/esa-panel/esa-panel.slice';
import fleetsPanelReducer from './maritime-menu-options/fleets-panel/fleets-panel.slice';
import historyPanelReducer from './maritime-menu-options/history-panel/history-panel.slice';
import incidentsPanelReducer from './maritime-menu-options/incidents-panel/incident-panel.slice';
import myFleetPanelReducer from './maritime-menu-options/my-fleet-panel/myFleet-panel.slice';
import rfDataPanelReducer from './maritime-menu-options/rf-data-panel/rf-data-panel.slice';
import routesPanelReducer from './maritime-menu-options/routes-panel/routes-panel.slice';
import subSeaInfraReducer from './maritime-menu-options/subsea-infrastructure/subsea-infrastructure.slice';
import historicVesselsReducer from './maritime-menu-options/tools-panel/historic-area-search/historic-area-search.slice';
import nearbyVesselsReducer from './maritime-menu-options/tools-panel/nearby-vessels/nearby-vessels.slice';
import UnseenPanelReducer from './maritime-menu-options/unseen-labs-panel/unseen-panel.slice';
import portsPanelReducer from './maritime-menu-options/world-ports-panel/world-ports-panel.slice';
import navHistoryReducer from './nav-history.slice';
import notePadReducer from './notepad/notepad.slice';
import searchPanelReducer from './search-bar/search-panel.slice';
import adtechReducer from './state/adtech-data/adtech.slice';
import alertingVesselsReducer from './state/alerts/alerts.slice';
import boundariesReducer from './state/boundaries/boundaries.slice';
import capellaReducer from './state/capella/capella.slice';
import citiesReducer from './state/cities/cities.slice';
import countriesReducer from './state/countries/countries.slice';
import documentsReducer from './state/documents/documents.slice';
import drawingsReducer from './state/drawings/drawings.slice';
import globalAisReducer from './state/global-ais/global-ais.slice';
import historyReducer from './state/history/history.slice';
import incidentsReducer from './state/incidents/incidents.slice';
import portsReducer from './state/ports/ports.slice';
import rfDataReducer from './state/rf-data/rf-data.slice';
import riMaritimeAreasReducer from './state/ri-maritime-areas/ri-maritime-areas.slice';
import routesReducer from './state/routes/routes.slice';
import toolsPanelReducer from './state/tools/tools.slice';
import myFleetReducer from './state/vessels/vessels.slice';
import weatherReducer from './state/weather/weather.slice';
import applySetupBarSliceReducer from './user-settings/apply-setup-bar/apply-setup-bar.slice';
import userPreferencesReducer from './user-settings/user-preferences/user-preferences.slice';
import userReducer from './user/user.slice';

const appReducer = combineReducers({
  admin: adminReducer,
  applySetupBar: applySetupBarSliceReducer,
  alertingVessels: alertingVesselsReducer,
  alertingPanel: alertingPanelReducer,
  boundaries: boundariesReducer,
  boundariesPanel: boundariesPanelReducer,
  cities: citiesReducer,
  citiesPanel: citiesPanelReducer,
  countries: countriesReducer,
  countriesPanel: countriesPanelReducer,
  history: historyReducer,
  historyPanel: historyPanelReducer,
  incidents: incidentsReducer,
  incidentsPanel: incidentsPanelReducer,
  map: mapReducer,
  menu: menuReducer,
  myFleet: myFleetReducer,
  myFleetPanel: myFleetPanelReducer,
  navHistory: navHistoryReducer,
  ports: portsReducer,
  portsPanel: portsPanelReducer,
  drawings: drawingsReducer,
  drawingsPanel: drawingsPanelReducer,
  routes: routesReducer,
  routesPanel: routesPanelReducer,
  searchPanel: searchPanelReducer,
  securityRiskAssessment: securityRiskAssessmentReducer,
  threatAssessment: threatAssessmentReducer,
  vulnerabilityAssessment: vulnerabilityAssessmentReducer,
  user: userReducer,
  userPreferences: userPreferencesReducer,
  vesselDossier: vesselDossierReducer,
  riMaritimeAreas: riMaritimeAreasReducer,
  webSocketEvents: webSocketEventsReducer,
  toolsPanel: toolsPanelReducer,
  unseenPanel: UnseenPanelReducer,
  documents: documentsReducer,
  documentsPanel: documentsPanelReducer,
  capella: capellaReducer,
  capellaPanel: capellaPanelReducer,
  nearbyVessels: nearbyVesselsReducer,
  historicVessels: historicVesselsReducer,
  timeline: timelineReducer,
  weather: weatherReducer,
  rfData: rfDataReducer,
  rfDataPanel: rfDataPanelReducer,
  adtech: adtechReducer,
  avcs: avcsReducer,
  adtechPanel: adtechPanelReducer,
  subSeaInfra: subSeaInfraReducer,
  mapLayerHandler: mapLayerHandlerReducer,
  globalAis: globalAisReducer,
  esaPanel: esaPanelReducer,
  notePad: notePadReducer,
  fleetsPanel: fleetsPanelReducer,
});

export const RESET_ACTION = {
  type: 'RESET',
};

type AppState = ReturnType<typeof appReducer>;

const rootReducer = (state: AppState | undefined, action: AnyAction) => {
  // https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
  // reset state of Redux Store
  if (action.type === RESET_ACTION.type) {
    localStorage.clear();
    return appReducer(undefined, { type: undefined });
  }

  return appReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // map: make map instance accessible to entire app. Allows us to perform map actions and listen to map events
        // selectedVessel: mapbox geojson feature - will allow us to modify properties or perform action on selected feature
        ignoredActions: ['map/setMap', 'vessel-dossier/setSelectedVessel'],
        ignoredPaths: ['map.map', 'vesselDossier.selectedVessel'],
      },
    }),
  devTools: {
    stateSanitizer: (state) => {
      const appState = state as AppState;
      return {
        ...state,
        map: {
          ...appState.map,
          map: '<<MAP INSTANCE>>' as any,
        },
      } as any;
    },
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
